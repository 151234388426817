import React from 'react';
import { generateRandomId } from '../../utils/random.util';
import { escapeRegexCharacters } from '../../utils/text.util';

type TextHighlightProps = {
  text: string;
  highlightedText: string;
  dataTestId?: string;
};

// This component will highlight any text that matches `highlightedText` string
export const TextHighlight = ({ text, highlightedText, dataTestId = 'text-highlight' }: TextHighlightProps) => {
  const escapedHighlight = escapeRegexCharacters(highlightedText);
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return (
    <span>
      {parts.map(part => {
        const id = generateRandomId('hl');
        return part.toLowerCase() === highlightedText.toLowerCase() ? (
          <span key={id} className="text-primary-pink-4" data-testid={`${dataTestId}-highlighted`}>
            {part}
          </span>
        ) : (
          <span key={id} data-testid={`${dataTestId}-normal`}>
            {part}
          </span>
        );
      })}
    </span>
  );
};
