import { useEffect, useRef } from 'react';
import { useDidomiSdk, useTranslationContext, WidgetPreviewMode } from '@didomi/widgets-utilities';
import { WidgetLayoutShape } from '@enums';
import { useActiveOrganization } from '@didomi/utility-react';
import { useFields, useUserRights, useWidgetLayoutComponents, useWidgetLayouts, useWidget, useWidgetThemes } from '@hooks';
import { snakeToCamelCase, buildWidgetPreviewPayload } from '@utils';
import { Widget, WidgetTheme } from '@interfaces';

interface UseWidgetPreviewIframeOptions {
  mode: WidgetPreviewMode;
  widgetId: string;
}

const getTheme = (widget: Widget, defaultThemes: WidgetTheme[]): WidgetTheme => {
  if (widget?.theme_id === widget?.custom_theme_id) {
    return widget?.custom_theme;
  }

  return defaultThemes.find(theme => theme.id === widget?.theme_id);
};

export const useWidgetPreviewIframe = ({ mode, widgetId }: UseWidgetPreviewIframeOptions) => {
  const { organization } = useActiveOrganization();
  const { defaultLanguage } = useTranslationContext();
  const iframeInitialized = useRef(false);

  const { data: fields } = useFields();
  const { data: userRights } = useUserRights();
  const { data: widgetLayoutComponents } = useWidgetLayoutComponents({
    widgetId,
  });
  const { data: widgetLayouts } = useWidgetLayouts({
    widgetId,
  });
  const { data: defaultThemes } = useWidgetThemes({ organizationId: 'null' });
  const { data: widget } = useWidget({
    widgetId,
    $with: ['custom_theme'],
  });

  const { iframe, setIframe, isSdkReady, initializeSdk, setWidgetLocale } = useDidomiSdk();

  useEffect(() => {
    if (!iframeInitialized.current || !isSdkReady || !defaultLanguage) {
      return;
    }

    setWidgetLocale(defaultLanguage);
  }, [isSdkReady, defaultLanguage, setWidgetLocale]);

  // Call the generator to structure the payload and initialize the SDK
  useEffect(() => {
    if (
      !iframeInitialized.current &&
      iframe &&
      organization &&
      fields?.data &&
      userRights?.data &&
      widgetLayoutComponents?.data &&
      widgetLayouts?.data &&
      defaultThemes?.data &&
      widget
    ) {
      const payload = buildWidgetPreviewPayload({
        theme: snakeToCamelCase({ ...getTheme(widget, defaultThemes.data) }),
        display: 'wrapped',
        layout: snakeToCamelCase(widgetLayouts.data[0]),
        components: widgetLayoutComponents.data,
        mode,
        organization,
        headless: widget.headless,
        layoutShape: widget.layout_shape as WidgetLayoutShape,
        layoutEntities: null,
        languages: {
          enabled: widget.enabled_languages,
          default: widget.default_language,
        },
        fields: fields.data,
        userRights: userRights.data,
      });
      iframeInitialized.current = true;
      initializeSdk(payload);
    }
  }, [
    organization,
    iframe,
    fields,
    userRights,
    widgetLayoutComponents,
    widgetLayouts,
    defaultThemes,
    widget,
    mode,
    initializeSdk,
    isSdkReady,
  ]);

  return { setIframe, isSdkReady };
};
