import { keyframes, animation } from '@twind/core';

export const createBackgroundColorHighlightAnimation = (targetClass: string, animatedBackgroundColor: string) => {
  const backgroundColorHighlight = keyframes({
    '0%': {
      'background-color': '--neutral-grey-1',
    },
    '50%': {
      'background-color': animatedBackgroundColor,
    },
    '100%': {
      'background-color': '--neutral-grey-1',
    },
  });

  return animation('2s ease', backgroundColorHighlight);
};

export const createUserRequestsStatusSelectAnimation = () => {
  const fadeInAnimation = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return animation('400ms ease in', fadeInAnimation);
};
