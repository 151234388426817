import { FetchDomainResult, HTTP_STATUS_CODE } from '@didomi/widgets-utilities';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';

export const useCheckWidgetDomain = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchDomain = async (domain: string): Promise<FetchDomainResult> => {
    try {
      const response = await axiosWithInterceptors.get(`${apiRoutes.domains}/${domain}`);
      return { httpCode: HTTP_STATUS_CODE.OK, widgetId: response.data.widget_id };
    } catch (error) {
      if (error.response?.status === HTTP_STATUS_CODE.NOT_FOUND) {
        return { httpCode: HTTP_STATUS_CODE.NOT_FOUND };
      }

      throw error;
    }
  };

  return {
    fetchDomain,
  };
};
