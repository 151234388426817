import React, { useCallback, useMemo, useState } from 'react';
import { DidomiRightPanel, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useCreateWidgetDeployment, useWidget, useWidgetPreview, useUpdateWidget, UpdateWidgetPayload } from '@hooks';
import { WidgetPreviewLayout, SecondaryLayout } from '@layouts';
import { useParams } from 'react-router';
import { EditWidgetHeaderActions } from './components/EditWidgetHeaderActions';
import { EditWidgetHeaderTitle } from './components/EditWidgetHeaderTitle';
import { Widget } from '@interfaces';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  EmbeddingRightPanel,
  HostingRightPanel,
  LanguageForm,
  useTranslationContext,
  NavigationPrompt,
  WidgetDomain,
  WidgetHostingHeader,
  WidgetDomain as WidgetUtilitiesDomain,
  WidgetPublicationStatus as WidgetUtilitiesPublicationStatus,
} from '@didomi/widgets-utilities';
import { useActiveOrganization, useSnackbar } from '@didomi/utility-react';
import { LoadingModal } from '@modals';
import { WidgetFormat, WidgetPublicationStatus } from '@enums';
import EditWidgetPublishedModal from './components/EditWidgetPublishedModal';
import { useWidgetDomain } from '../../hooks/data/domains/useWidgetDomain.hook';
import DidomiLogo from '../../assets/svg/didomi-logo.svg';

export const EditWidget = () => {
  const { organization } = useActiveOrganization();
  const { displaySnackbar } = useSnackbar();
  const {
    defaultLanguage,
    enabledLanguages,
    setDefaultLanguage,
    setEnabledLanguages,
    setIsLoading: setIsLanguagesLoading,
    hasLanguageChanged,
  } = useTranslationContext();
  const { id: widgetId } = useParams<{ id: string }>();
  const [widgetName, setWidgetName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const history = useHistory();

  const { data: widget, isLoading: isWidgetLoading } = useWidget({
    widgetId,
    onSuccess: (widget: Widget) => {
      setWidgetName(widget.name);
      setDefaultLanguage(widget.default_language);
      setEnabledLanguages(widget.enabled_languages);
      setIsLanguagesLoading(false);
    },
  });

  const { isLoading: isLoadingPreview, preview: previewWidget } = useWidgetPreview({
    languages: {
      default: defaultLanguage,
      enabled: enabledLanguages,
    },
  });

  const initialSubDomain = useMemo(() => (widget?.subdomain_id || '')?.split('.')[0], [widget?.subdomain_id]);

  const { hasError: hasErrorOnWidgetDomain, widgetDomainInput, isDomainBooked } = useWidgetDomain({ initialSubDomain, widgetId });

  const { isLoading: isWidgetUpdating, mutateAsync: updateWidgetAsync } = useUpdateWidget({
    onSettled: () => {
      setIsSaving(false);
    },
    onSuccess: () => {
      displaySnackbar('Your widget is saved', { icon: 'check' });
    },
    onError: () => {
      displaySnackbar('Widget can not be saved', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
  });

  const { isLoading: deployingWidget, mutate: createWidgetDeployment } = useCreateWidgetDeployment({
    onSuccess: () => {
      setIsPublishModalOpen(true);
    },
    onError: err => {
      const errorResponse = err?.response;
      displaySnackbar('An error occurred: your widget has not been published', { variant: 'error' });

      if (isDomainBooked(errorResponse?.data?.code)) {
        history.push(`/widgets/edit/${widgetId}/hosting`);
      }
    },
  });

  const isLoading = isWidgetLoading || isWidgetUpdating;

  const handleSave = async () => {
    setIsSaving(true);

    const payload: UpdateWidgetPayload = {
      id: widgetId,
      name: widgetName,
      default_language: defaultLanguage,
      enabled_languages: enabledLanguages,
    };

    if (widget.domain === WidgetDomain.SUB.toLowerCase() && hasSubdomainChanged) {
      payload.domain = WidgetDomain.SUB.toLowerCase();
      payload.subdomain_id = `${widgetDomainInput.value}.${CONFIG.hostingDomain}`;
    }

    return updateWidgetAsync(payload);
  };

  const handlePublish = async () => {
    await handleSave();
    createWidgetDeployment({ widget_id: widgetId });
  };

  const hasWidgetNameChanged = widget?.name !== widgetName && widgetName !== '';
  const hasWidgetLanguageChanged = hasLanguageChanged(widget?.default_language, widget?.enabled_languages);
  const hasSubdomainChanged =
    !!initialSubDomain && widgetDomainInput?.value && widgetDomainInput?.value !== initialSubDomain && !hasErrorOnWidgetDomain;
  const shouldAllowRouteChange = useCallback((newPath: string) => newPath.startsWith(`/widgets/edit/${widgetId}/`), [widgetId]);
  const hasDataChanged = hasWidgetNameChanged || hasWidgetLanguageChanged || hasSubdomainChanged;

  const isWidgetPublished = widget?.publication_status === WidgetPublicationStatus.PUBLISHED;

  const isSaveButtonDisabled = isSaving || !hasDataChanged;
  const isPublishButtonDisabled = isSaving || deployingWidget || (isWidgetPublished && !hasDataChanged);

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading} data-testid={`edit-widget-loading-${isLoading}`}>
      <SecondaryLayout
        backPath="/widgets"
        backText="Back to widgets"
        fullWidth
        headerChildren={
          <EditWidgetHeaderTitle
            isLoading={isLoading}
            widgetName={widgetName}
            onWidgetNameChange={widgetName => setWidgetName(widgetName)}
          />
        }
        actionChildren={
          <EditWidgetHeaderActions
            isLoading={isLoading}
            isLoadingPreview={isLoadingPreview}
            widgetPublicationStatus={widget?.publication_status}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isPublishButtonDisabled={isPublishButtonDisabled}
            onPreviewWidget={() => previewWidget(widget?.id)}
            onSave={async () => {
              try {
                await handleSave();
              } catch (error) {
                // This is handled by onReactQueryError method in the root.component.
              }
            }}
            onPublish={handlePublish}
          />
        }
      >
        <WidgetPreviewLayout
          widgetId={widgetId}
          isLoading={isLoading}
          format={widget?.format}
          headerChildren={
            <Route exact path="/widgets/edit/:id/hosting">
              <WidgetHostingHeader
                didomiLogo={DidomiLogo}
                domain={widget?.domain?.toUpperCase() as unknown as WidgetUtilitiesDomain}
                publicationStatus={widget?.publication_status?.toUpperCase() as unknown as WidgetUtilitiesPublicationStatus}
                customDomainId={widget?.custom_domain_id}
                subdomainId={widget?.subdomain_id}
              />
            </Route>
          }
        >
          <Switch>
            <Route exact path="/widgets/edit/:id/languages">
              <DidomiRightPanel titleText="Languages" className="h-full" data-testid="languages-right-panel" showCreateButton={false}>
                <div slot="content" className="h-full">
                  <LanguageForm />
                </div>
              </DidomiRightPanel>
            </Route>

            {widget?.format === WidgetFormat.EMBEDDABLE && (
              <Route exact path="/widgets/edit/:id/embedding">
                <EmbeddingRightPanel
                  widgetId={widgetId}
                  apiKey={organization.public_api_key}
                  isLoading={isLoading}
                  sdkLoader={CONFIG.sdkLoader}
                />
              </Route>
            )}

            {widget?.format === WidgetFormat.HOSTED && (
              <Route exact path="/widgets/edit/:id/hosting">
                <HostingRightPanel
                  isLoading={isLoading}
                  useYourDomainCardTitle="Use your own domain."
                  useYourDomainCardDescription={
                    'We recommend using your own domain name instead of the one assigned to your Privacy request widget.'
                  }
                  widgetDomainInputProps={widgetDomainInput}
                  widgetDomain={widget?.domain?.toUpperCase()}
                  widgetCustomDomainId={widget?.custom_domain_id}
                />
              </Route>
            )}
          </Switch>
        </WidgetPreviewLayout>
      </SecondaryLayout>

      <LoadingModal isOpen={isSaving} title="We are saving the widget..." />
      <NavigationPrompt
        when={!isLoading && hasDataChanged}
        title="Your changes will be lost"
        subTitle="Please pay attention"
        description="Are you sure you want to leave your Widget configuration without saving?"
        okText="Keep on editing"
        cancelText="Don’t save"
        actionText="Save"
        illustration="widget-lost"
        onOK={() => true}
        onIntermediateAction={async () => {
          const result = handleSave().then(() => {
            return true;
          });
          return result;
        }}
        onCancel={() => true}
        shouldAllowRouteChange={shouldAllowRouteChange}
      />

      <EditWidgetPublishedModal
        isOpen={isPublishModalOpen}
        widgetId={widgetId}
        widgetFormat={widget?.format}
        widgetDomain={widget?.domain}
        widgetSubdomainId={widget?.subdomain_id}
        widgetCustomDomainId={widget?.custom_domain_id}
        onCloseModal={() => setIsPublishModalOpen(false)}
      />
    </DidomiSkeleton>
  );
};
