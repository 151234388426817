import { useCallback, useState } from 'react';
import { useResizeObserver } from '@hooks';

export const useIsOverflowing = (ref: HTMLElement) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const target = entries[0].target;

    // is overflowing when the visible width is less than the actual width
    setIsOverflowing(target.clientWidth < target.scrollWidth);
  }, []);

  useResizeObserver(ref, handleResize);

  return isOverflowing;
};
