import React from 'react';
import { NavLink } from 'react-router-dom';
import { DidomiNavTabs, DidomiNavTabHeading, DidomiNavTabHeader, DidomiNavTabHeaders, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { WidgetPreviewIframe, WidgetPreviewMode } from '@didomi/widgets-utilities';
import { useWidgetPreviewIframe } from '@hooks';
import { WidgetFormat } from '@enums';

interface WidgetLayoutProps {
  widgetId: string;
  format: WidgetFormat;
  isLoading: boolean;
  headerChildren?: React.ReactChild;
  children?: React.ReactChild;
}

/**
 * Widget Preview Layout
 */
export const WidgetPreviewLayout = ({ children, widgetId: id, headerChildren, isLoading, format }: WidgetLayoutProps): JSX.Element => {
  // TODO: To make the mode dynamic
  const { setIframe, isSdkReady } = useWidgetPreviewIframe({ mode: WidgetPreviewMode.LookAndFeel, widgetId: id });

  const NavTabs: JSX.Element = (
    <DidomiNavTabs data-testid={'widget-nav-tabs'}>
      <DidomiNavTabHeading variant="flow" className="mb-m">
        <DidomiNavTabHeaders>
          <DidomiNavTabHeader>
            <NavLink data-testid="nav-link-languages" activeClassName="didomi-active-tab" to={`/widgets/edit/${id}/languages`}>
              Languages
            </NavLink>
          </DidomiNavTabHeader>
          {format === WidgetFormat.EMBEDDABLE && (
            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-embedding" activeClassName="didomi-active-tab" to={`/widgets/edit/${id}/embedding`}>
                Embedding
              </NavLink>
            </DidomiNavTabHeader>
          )}

          {format === WidgetFormat.HOSTED && (
            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-hosting" activeClassName="didomi-active-tab" to={`/widgets/edit/${id}/hosting`}>
                Hosting
              </NavLink>
            </DidomiNavTabHeader>
          )}
        </DidomiNavTabHeaders>
      </DidomiNavTabHeading>
    </DidomiNavTabs>
  );

  return (
    <div className="h-full flex flex-col">
      {isLoading && !isSdkReady ? (
        <div className="flex gap-x-6 mb-m">
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
        </div>
      ) : (
        NavTabs
      )}

      <div className="flex flex-1 w-full">
        <div className="flex-1 mr-14 h-full">
          <div className="flex flex-1 flex-col h-full">
            {headerChildren}
            <WidgetPreviewIframe
              isLoading={isLoading || !isSdkReady}
              previewURL={`${CONFIG.previewURL}/assets/preview.html`}
              setIframe={setIframe}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
