import React, { useState, useEffect } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { DidomiTableCard, DidomiTextarea, DidomiButton } from '@didomi/ui-atoms-react';
import { Request } from '../../../../interfaces';
import { useCreateRequestNote } from '../../../../hooks/data/request-notes/useCreateRequestNote.hook';
import { Note } from '../../../../interfaces/note.interface';

interface UserRequestDetailsNoteProps {
  request: Request;
  onRequestNoteCreated?: (note: Note) => void;
}

export const UserRequestDetailsNote = ({ request, onRequestNoteCreated }: UserRequestDetailsNoteProps) => {
  const fetchedNoteText = request?.notes?.length === 0 ? '' : request?.notes[request.notes.length - 1].value;

  const [noteText, setNoteText] = useState(fetchedNoteText);
  const [isEditMode, setIsEditMode] = useState(false);
  const { displaySnackbar } = useSnackbar();
  const { mutate: createNote, isLoading } = useCreateRequestNote({
    onSuccess: res => {
      onRequestNoteCreated?.(res.data);
      setIsEditMode(false);
      displaySnackbar('Your note has been saved!', { icon: 'check' });
    },
    onError: () => displaySnackbar('Sorry, an error occured: your note cannot be saved', { variant: 'error' }),
  });

  useEffect(() => setNoteText(fetchedNoteText), [fetchedNoteText]);

  const handleCancel = () => {
    setNoteText(fetchedNoteText);
    setIsEditMode(false);
  };

  const handleSave = () => createNote({ request_id: request.id, value: noteText });

  const handleEdit = () => setIsEditMode(true);

  const handleChange = event => setNoteText((event as any)?.detail);

  const EditorButtons = () => (
    <div className="flex justify-end gap-x-4">
      {isEditMode ? (
        <>
          <DidomiButton disabled={isLoading} variant="secondary" size="small" onClick={handleCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton disabled={isLoading} size="small" onClick={handleSave} data-testid="details-note-save-button">
            Save
          </DidomiButton>
        </>
      ) : (
        <DidomiButton size="small" onClick={handleEdit} data-testid="details-note-edit-button">
          Edit
        </DidomiButton>
      )}
    </div>
  );

  return (
    <DidomiTableCard headerText="Ticket note">
      <div slot="content" className="flex-col">
        {isEditMode ? (
          <DidomiTextarea value={noteText} onValueChange={handleChange} autoFocus={false} placeholder="Type your note here" />
        ) : (
          <div className="italic whitespace-pre-wrap">
            {' '}
            {noteText || <span className="opacity-50"> Add a note for your own reference. </span>}{' '}
          </div>
        )}
        <EditorButtons />
      </div>
    </DidomiTableCard>
  );
};
