import React from 'react';
import { DidomiChip } from '@didomi/ui-atoms-react';
import { UserRightId, WidgetPublicationStatus } from '../enums';

export const WIDGET_STATUS_LABELS = {
  [WidgetPublicationStatus.DRAFT]: 'Draft',
  [WidgetPublicationStatus.UNPUBLISHED_CHANGES]: 'Unpublished Changes',
  [WidgetPublicationStatus.PUBLISHED]: 'Published',
};

export const WIDGET_STATUS_CHIP_TYPE: { [key in WidgetPublicationStatus]: React.ComponentProps<typeof DidomiChip>['basicType'] } = {
  [WidgetPublicationStatus.DRAFT]: 'default',
  [WidgetPublicationStatus.UNPUBLISHED_CHANGES]: 'warning',
  [WidgetPublicationStatus.PUBLISHED]: 'success',
};

export const WIDGET_USERS_RIGHTS_LABELS = {
  [`${UserRightId.CPRA_ACCESS_MY_DATA}`]: 'Access data request',
  [`${UserRightId.CPRA_DELETE_MY_DATA}`]: 'Delete data request',
  [`${UserRightId.CPRA_OPT_OUT}`]: 'Opt-out of sales request',
};

// TODO: To remove once we have dynamic users rights
export const WIDGET_STATIC_USERS_RIGHTS = [UserRightId.CPRA_ACCESS_MY_DATA, UserRightId.CPRA_DELETE_MY_DATA, UserRightId.CPRA_OPT_OUT];
