import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { apiRoutes } from '@constants';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '@interfaces';

export type UpdateWidgetPayload = Partial<Widget>;

export const useUpdateWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, UpdateWidgetPayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (data: UpdateWidgetPayload) => {
    const { id, ...payload } = data;

    return await axiosWithInterceptors.patch<Widget>(`${apiRoutes.widgets}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Widget>, AxiosError, UpdateWidgetPayload>(patch, {
    ...options,
    onSuccess: (updatedEntity: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widget'
      const dataKey = ['widget'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Widget> | Widget) => {
        if (!data) {
          return;
        }

        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            data: data.data.map(entity => (entity.id === variables.id ? { ...entity, ...updatedEntity.data } : entity)),
          };
        } else if ('id' in data && data.id === updatedEntity.data.id) {
          return { ...data, ...updatedEntity.data };
        }
      });

      if (options?.onSuccess) {
        options.onSuccess(updatedEntity, variables, context);
      }
    },
  });
};
