import { DidomiErrorState, DidomiSkeleton, DidomiTableCard, DidomiTimeline } from '@didomi/ui-atoms-react';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from '../../hooks/data/requests/useRequest.hook';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';
import { UserRequestDetailsHeader } from './components/UserRequestDetailsHeader/UserRequestDetailsHeader';
import { UserRequestDetailsRequestInfos } from './components/UserRequestDetailsRequestInfos/UserRequestDetailsRequestInfos';
import { UserRequestDetailsAdditionalInfos } from './components/UserRequestDetailsAdditionalInfos/UserRequestDetailsAdditionalInfos';
import { UpdateRequestStatusDropdown } from '@components';
import { dashboardRoute } from '../../constants/routes';
import { useInteractionEvents } from '../../hooks/data/interaction-events/useInteractionEvents.hook';
import { UserRequestDetailsNote } from './components/UserRequestDetailsNote/UserRequestDetailsNote';
import { UserRequestDetailsTimelineItem } from './components/UserRequestDetailsTimelineItem/UserRequestDetailsTimelineItem';

export const UserRequestDetails = () => {
  const { id: requestId } = useParams<{ id: string }>();
  const { isLoading: isLoadingRequest, isError: isErrorRequest, refetch: refetchRequest, data: request } = useRequest(requestId);
  const isAdditionalInfosEmpty = !isLoadingRequest && (request?.request_fields || []).length === 0;
  const { isLoading: isAccessPoliciesLoading, hasAdminAccess, hasDsarRequestsEditAccess, hasDsarRequestsViewAccess } = useAccessPolicies();
  const history = useHistory();

  const {
    isLoading: isLoadingEvents,
    isRefetching: isRefetchingEvents,
    data: events,
    refetch: refetchEvents,
  } = useInteractionEvents({}, { requestId });
  const isLoading = isLoadingRequest || isAccessPoliciesLoading || isLoadingEvents;

  // We need to know when a first refetch happened to start animating the timeline
  const hasRefetchedEventRef = useRef(false);
  useEffect(() => {
    if (isRefetchingEvents && !hasRefetchedEventRef.current) {
      hasRefetchedEventRef.current = true;
    }
  }, [isRefetchingEvents]);

  useLayoutEffect(() => {
    if (isAccessPoliciesLoading) return;

    if (!hasAdminAccess && !hasDsarRequestsEditAccess && !hasDsarRequestsViewAccess) history.push(dashboardRoute);
  }, [hasAdminAccess, hasDsarRequestsEditAccess, hasDsarRequestsViewAccess, history, isAccessPoliciesLoading]);

  const RequestInfo = () => <UserRequestDetailsRequestInfos request={request} data-testid="user-request-details-info" />;
  const RequestAdditionalInfos = () => (
    <UserRequestDetailsAdditionalInfos request={request} data-testid="user-request-details-additional-infos" />
  );

  const SectionSkeleton = ({ dataTestId }: { dataTestId: string }) => (
    <DidomiSkeleton isLoading={true} data-testid={dataTestId} className="h-[150px] w-full rounded-[8px]"></DidomiSkeleton>
  );

  return (
    <>
      <div className="flex justify-between" data-testid={`user-request-details-loading-${isLoading}`}>
        {!isErrorRequest && (
          <DidomiSkeleton isLoading={isLoading} data-testid="user-request-details-header-skeleton">
            <UserRequestDetailsHeader requestId={requestId} userRight={request?.user_right} data-testid="user-request-details-header" />
          </DidomiSkeleton>
        )}

        {!isErrorRequest && (
          <DidomiSkeleton isLoading={isLoading} data-testid="user-request-details-header-status-skeleton">
            {!isLoading && (
              <UpdateRequestStatusDropdown
                request={request}
                dropdownSize={'md'}
                data-testid="user-request-details-header-status"
                onRequestStatusChange={() => refetchEvents()}
              />
            )}
          </DidomiSkeleton>
        )}
      </div>

      {isErrorRequest && (
        <DidomiErrorState
          className="mt-8 h-full min-h-[70vh]"
          illustrationName="content-loading-error"
          actionName="Reload"
          actionIcon="reset"
          onActionClick={() => refetchRequest()}
        >
          <div slot="title">There seems to be an error</div>
          <div slot="description">The request has not been loaded</div>
        </DidomiErrorState>
      )}

      {!isErrorRequest && (
        <div className="grid gap-x-12 mt-8" style={{ gridTemplateColumns: '2fr 1fr' }}>
          <div className="grid gap-y-6">
            {isLoading && (
              <>
                <SectionSkeleton dataTestId={'user-request-details-info-skeleton'}></SectionSkeleton>
                <SectionSkeleton dataTestId={'user-request-details-additional-infos-skeleton'}></SectionSkeleton>
                <SectionSkeleton dataTestId={'user-request-details-timeline'}></SectionSkeleton>
              </>
            )}

            {!isLoading && (
              <>
                <RequestInfo />
                {!isAdditionalInfosEmpty && <RequestAdditionalInfos />}

                <DidomiTableCard headerText="Request history" size="sm" contentPadding="0 24px">
                  <div slot="content">
                    <DidomiTimeline slot="content" data-testid="user-request-details-timeline">
                      {events?.data?.map((event, i) => (
                        <UserRequestDetailsTimelineItem
                          key={event.id}
                          event={event}
                          withBoldLabels={i === 0}
                          animate={i === 0 && hasRefetchedEventRef.current}
                        ></UserRequestDetailsTimelineItem>
                      ))}
                    </DidomiTimeline>
                  </div>
                </DidomiTableCard>
              </>
            )}
          </div>
          {isLoading && <SectionSkeleton dataTestId={'user-request-details-notes-skeleton'}></SectionSkeleton>}

          {!isLoading && (
            <div>
              <UserRequestDetailsNote request={request} onRequestNoteCreated={() => refetchEvents()} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
