import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '../../../constants/apiRoutes';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Member } from '@interfaces';

const ENTITIES_FETCH_LIMIT = 1000;

export const useMembers = (options?: UseQueryOptions<PaginatedResponse<Member>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Member>>(apiRoutes.members, {
      params: { organization_id: organizationId, $limit: ENTITIES_FETCH_LIMIT },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<Member>, AxiosError>(['members', { organization_id: organizationId }], fetch, {
    ...options,
    onSuccess: (res: PaginatedResponse<Member>) => {
      if (options?.onSuccess) {
        options.onSuccess(res);
      }
    },
  });
};
