import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PaginatedResponse, RequestConfigsResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { apiRoutes } from '../../../constants/apiRoutes';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';

export const useRequestConfigs = (options?: UseQueryOptions<PaginatedResponse<RequestConfigsResponse>>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<RequestConfigsResponse>>(apiRoutes.requestConfigs, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<RequestConfigsResponse>, AxiosError>([`request-configs`, { organization_id: organizationId }], fetch, {
    ...options,
    onSuccess: (res: PaginatedResponse<RequestConfigsResponse>) => {
      if (options?.onSuccess) {
        options.onSuccess(res);
      }
    },
  });
};
