import React, { useState, useLayoutEffect, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { tx } from '@twind/core';
import { useRequests } from '../../hooks/data/requests/useRequests.hook';
import { DidomiEmptyState, DidomiErrorState, DidomiFiltersBar, DidomiPaginator, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { REQUEST_FILTERS } from '../../constants';
import { useUserRequestFilters } from './hooks/useUserRequestFilters';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';
import { createBackgroundColorHighlightAnimation } from '../../constants/animations';
import { UserRequestsTableCard } from './components/UserRequestsTableCard/UserRequestsTableCard';
import { RequestStatus } from '@enums';
import { dashboardRoute, widgetsRoute } from '../../constants/routes';
import { useGTM, useSPARouter, useSnackbar } from '@didomi/utility-react';
import { useWidgets } from '../../hooks/data/widgets/useWidgets.hook';
import { useCreateWidget } from '../../hooks/data/widgets/useCreateWidget.hook';

const REQUEST_STATUS_BACKGROUND_ANIMATED_COLOR_MAP: Record<RequestStatus, string> = {
  [RequestStatus.UNVERIFIED]: '#FCFDFE',
  [RequestStatus.VERIFIED]: '#D6EEF7',
  [RequestStatus.WORK_IN_PROGRESS]: '#FFF6DF',
  [RequestStatus.FULFILLED]: '#DAEED8',
  [RequestStatus.ARCHIVED]: '#E9EAEA',
  [RequestStatus.REFUSED]: '#EFCFCC',
};

export const UserRequests = (): JSX.Element => {
  const { navigateTo } = useSPARouter();
  const { displaySnackbar } = useSnackbar();
  const { push } = useHistory();
  const { pushEventToDataLayer } = useGTM();

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const { filters, handleSearchTextChange, handleFilterValueChange, handleClearAllFilters, hasFilterApplied } = useUserRequestFilters();
  const [requestHeaderHighlightData, setRequestHeaderHighlightData] = useState<{ backgroundColor: string; requestId: string }>({
    backgroundColor: '',
    requestId: '',
  });

  const backgroundAnimation = createBackgroundColorHighlightAnimation(
    '.didomi-table-card-header',
    requestHeaderHighlightData.backgroundColor,
  );

  const {
    isLoading: isLoadingAccessPolicies,
    hasAdminAccess,
    hasDsarRequestsEditAccess,
    hasDsarRequestsViewAccess,
    hasDsarWidgetsViewAccess,
    hasDsarWidgetsEditAccess,
  } = useAccessPolicies();

  const {
    isLoading: isLoadingRequests,
    isFetching: isFetchingRequests,
    isError: isErrorRequests,
    refetch: refetchRequests,
    paginator: { page, limit, setLimit, setPage },
    data: { data: userRequests, total: userRequestsCount } = {},
  } = useRequests(filters);

  // Widgets are only loaded if we have view access for the Widgets and there are no User Requests created
  const areWidgetsEnabled = userRequestsCount === 0 && hasDsarWidgetsViewAccess === true;
  const {
    isLoading: isLoadingWidgets,
    isError: isErrorWidgets,
    refetch: refetchWidgets,
    data: { total: widgetsCount } = {},
  } = useWidgets({ enabled: areWidgetsEnabled });

  // We are waiting for the Widgets to load only if the Widgets are enabled, otherwise the loading will never end
  const isLoading = isLoadingRequests || isLoadingAccessPolicies || (areWidgetsEnabled && isLoadingWidgets);

  const getLeftText = () => {
    if (isLoading) {
      return 'Loading..';
    }

    if (isError) {
      return null;
    }

    if (userRequestsCount === 1) {
      return `${userRequestsCount} ticket`;
    }

    return `${userRequestsCount} tickets`;
  };

  const isError = isErrorRequests || isErrorWidgets;
  const showUserRequests = !isLoading && !isError;
  const showRequestsEmptyState = !isLoading && !isError && userRequestsCount === 0;
  const showWidgetsEmptyState = showRequestsEmptyState && !isLoadingWidgets && widgetsCount === 0;
  const showPaginator = !isLoading && !isError && userRequests.length > 0;
  const emptyStateText = hasFilterApplied ? 'No matches' : "You don't have any user request yet";
  const emptyStateIllustration = hasFilterApplied ? 'traces-no-result-found' : 'traces-no-match-found';

  const refetch = () => {
    if (isErrorRequests) {
      refetchRequests();
    }
    if (isErrorWidgets) {
      refetchWidgets();
    }
  };

  /**
   * Clear the highlighted header after a filter or pagination change.
   * This fixes the highligh header reappear when the list is rerendered.
   */
  useEffect(() => {
    setRequestHeaderHighlightData({ requestId: '', backgroundColor: '' });
  }, [filters, page, limit]);

  // TODO, once the Widget Creation page is ready, remove this code, and add redirect to the Widget Creation page
  const { mutate: createWidget } = useCreateWidget({
    onSuccess: () => {
      displaySnackbar('Your widget has been created!', { icon: 'check' });
      push(widgetsRoute);
    },
    onError: () => displaySnackbar('An error occurred: your widget has not been created', { variant: 'error' }),
  });

  const handleWidgetCreation = () => createWidget();

  useLayoutEffect(() => {
    if (isLoadingAccessPolicies) return;

    if (!hasAdminAccess && !hasDsarRequestsEditAccess && !hasDsarRequestsViewAccess) {
      navigateTo(dashboardRoute);
    }
  }, [hasAdminAccess, hasDsarRequestsEditAccess, hasDsarRequestsViewAccess, isLoadingAccessPolicies, navigateTo]);

  useEffect(() => {
    if (isInitialLoad && userRequests?.length > 0) {
      setIsInitialLoad(false);
      return;
    }
  }, [isInitialLoad, userRequests]);

  const filtersWithValues = useMemo(() => {
    const updatedFilters = {};

    Object.entries(REQUEST_FILTERS).forEach(([key, obj]) => {
      updatedFilters[key] = {
        ...obj,
        value: filters[key],
      };
    });

    return updatedFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [REQUEST_FILTERS]);

  const TableCards = () => (
    <>
      {userRequests?.map((userRequest, index) => (
        <UserRequestsTableCard
          key={userRequest.id}
          request={userRequest}
          searchText={filters.searchText}
          isFetching={isFetchingRequests}
          headerClass={requestHeaderHighlightData.requestId === userRequest.id ? backgroundAnimation : null}
          className={tx({
            'mb-s': userRequests.length - 1 !== index,
            'mb-xs': userRequests.length - 1 === index,
          })}
          onRequestStatusChange={status => {
            if (hasFilterApplied) {
              refetchRequests();
            }
            setRequestHeaderHighlightData({
              requestId: userRequest.id,
              backgroundColor: REQUEST_STATUS_BACKGROUND_ANIMATED_COLOR_MAP[status],
            });
          }}
        />
      ))}
    </>
  );

  return (
    <div className="w-full mt-xxs" data-testid={`user-requests-loading-${isLoading}`}>
      {(!isLoading || !isInitialLoad) && (
        <DidomiFiltersBar
          className="block mb-xxs"
          searchValue={filters.searchText}
          leftText={getLeftText()}
          filters={filtersWithValues}
          showSearch={true}
          collapsibleFilters={false}
          onSearchTextChange={handleSearchTextChange}
          onFilterValueChange={handleFilterValueChange}
          onClearAllFilters={handleClearAllFilters}
          placeholderTextFilter={'Search by Ticket or User ID'}
          data-testid="user-requests-filters"
          searchInputCollapsibleWidth="320"
        ></DidomiFiltersBar>
      )}

      {isLoading && isInitialLoad && (
        <div className="flex items-center mt-xxs">
          <DidomiSkeleton
            isLoading={true}
            className="h-[30px] w-[100px] mb-xs rounded-[8px]"
            data-testid={`filter-bar-skeleton-1`}
          ></DidomiSkeleton>
          <DidomiSkeleton
            isLoading={true}
            className="h-[60px] w-[212px] mb-xs rounded-[8px] ml-auto"
            data-testid={`filter-bar-skeleton-2`}
          ></DidomiSkeleton>
          <DidomiSkeleton
            isLoading={true}
            className="h-[60px] w-[212px] mb-xs rounded-[8px] ml-xxs"
            data-testid={`filter-bar-skeleton-3`}
          ></DidomiSkeleton>
          <DidomiSkeleton
            isLoading={true}
            className="h-[60px] w-[212px] mb-xs rounded-[8px] ml-xxs"
            data-testid={`filter-bar-skeleton-4`}
          ></DidomiSkeleton>
          <DidomiSkeleton
            isLoading={true}
            className="h-[60px] w-[60px] mb-xs rounded-[8px] ml-xxs"
            data-testid={`filter-bar-skeleton-5`}
          ></DidomiSkeleton>
        </div>
      )}

      {isLoading &&
        Array.from(Array(limit), (_, i) => {
          return (
            <DidomiSkeleton
              key={i}
              isLoading={true}
              className="h-[205px] w-full mb-xs rounded-[8px]"
              data-testid={`user-request-skeleton-${i + 1}`}
            ></DidomiSkeleton>
          );
        })}

      {isError && (
        <DidomiErrorState illustrationName="content-loading-error" actionName="Reload" actionIcon="reset" onActionClick={() => refetch()}>
          <div slot="title">There seems to be an error</div>
          <div slot="description">The request has not been loaded</div>
        </DidomiErrorState>
      )}

      {showUserRequests && <TableCards />}

      {showRequestsEmptyState && !showWidgetsEmptyState && (
        <DidomiEmptyState illustrationName={emptyStateIllustration}>
          <div slot="title">{emptyStateText}</div>
          {hasFilterApplied && 'Sorry, no matches were found'}
        </DidomiEmptyState>
      )}

      {showWidgetsEmptyState && (
        <DidomiEmptyState
          illustrationName={emptyStateIllustration}
          actionName={hasDsarWidgetsEditAccess ? 'Create a widget' : null}
          actionIconRight={hasDsarWidgetsEditAccess ? 'new-create' : ''}
          onActionClick={handleWidgetCreation}
        >
          <div slot="title">You don't have any widget yet</div>
          You have to create and publish a widget in order to receive requests.
        </DidomiEmptyState>
      )}

      <div className="flex justify-end pb-s">
        {showPaginator ? (
          <DidomiPaginator
            data-testid="widget-paginator"
            className="self-end mb-s"
            page={page}
            itemCount={userRequestsCount}
            size={limit}
            onPageSizeChange={(ev: CustomEvent<{ limit: number }>) => {
              const pageLimit = ev.detail.limit;
              setLimit(ev);

              pushEventToDataLayer && pushEventToDataLayer('dsar-pagination', { selection_value: pageLimit });
            }}
            onPageChange={setPage}
          />
        ) : null}
      </div>
    </div>
  );
};
