import { useWidgetDomain as useWidgetDomainShared } from '@didomi/widgets-utilities';
import { useCheckWidgetDomain } from './useCheckWidgetDomain.hook';

export interface UseWidgetDomainOptions {
  initialSubDomain?: string;
  widgetId?: string;
}

export const useWidgetDomain = ({ initialSubDomain, widgetId }: UseWidgetDomainOptions) => {
  const { fetchDomain } = useCheckWidgetDomain();

  return useWidgetDomainShared({
    rootDomain: CONFIG.hostingDomain,
    initialSubDomain,
    widgetId,
    fetchDomain,
  });
};
