import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { PaginatedResponse, RequestConfigsResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { apiRoutes } from '../../../constants/apiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';

export type UpdateRequestConfigPayload = {
  id: string;
  member_ids: string[];
};

export const useUpdateRequestConfig = (
  options?: UseMutationOptions<AxiosResponse<RequestConfigsResponse>, AxiosError, UpdateRequestConfigPayload>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (data: UpdateRequestConfigPayload) => {
    const { id, ...payload } = data;

    return await axiosWithInterceptors.patch<RequestConfigsResponse>(`${apiRoutes.requestConfigs}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(patch, {
    ...options,
    onSuccess: (updatedEntity: AxiosResponse<RequestConfigsResponse>, variables, context) => {
      const dataKey = ['request-configs', { organization_id: organizationId }];
      const getData: PaginatedResponse<RequestConfigsResponse> = queryClient.getQueryData(dataKey);

      if (getData) {
        queryClient.setQueryData(dataKey, {
          ...getData,
          data: getData.data.map(entity => (entity.id === variables.id ? { ...entity, ...updatedEntity.data } : entity)),
        });
      }

      if (options?.onSuccess) {
        options.onSuccess(updatedEntity, variables, context);
      }
    },
  });
};
