import { UserRight } from '@interfaces';
import { USER_REQUEST_ID_LABELS } from '@constants';

export const getUserRightLabel = (userRight: UserRight) => {
  if (userRight) {
    return USER_REQUEST_ID_LABELS[userRight.id] || userRight.name;
  }

  return null;
};
