import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DidomiFeatureHeader,
  DidomiNavTabs,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabHeaders,
  DidomiNavTabContents,
  DidomiNavTabContent,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ChatBubbleSpacer } from '../../components/ChatBubbleSpacer/ChatBubbleSpacer';
import { requestsRoute, widgetsRoute } from '../../constants/routes';

interface IMainLayoutProps {
  children: React.ReactChild;
  loading?: boolean;
  disableUserRequestsTab?: boolean;
  disableWidgetsTab?: boolean;
  Action?: FunctionComponent<any>;
  TitleAction?: FunctionComponent<any>;
}

/**
 * Main Layout
 * Default layout with header and tab navs for second level pages
 */
const MainLayout = ({
  children,
  loading,
  disableUserRequestsTab,
  disableWidgetsTab,
  Action,
  TitleAction,
}: IMainLayoutProps): JSX.Element => (
  <section className="p-12 pb-7 h-full box-border !flex flex-col">
    <DidomiSkeleton isLoading={loading} variant="layout" className="flex-1 flex flex-col" data-testid="main-layout-skeleton">
      <div className="flex mb-8">
        <DidomiFeatureHeader titleText="Privacy Requests" className="w-full" style={{ display: 'block' }}>
          <div slot="description">This module allows you to easily collect and manage user requests.</div>
          <div slot="title-actions" data-skeleton>
            {TitleAction && <TitleAction />}
          </div>
          <div slot="actions" data-skeleton>
            {Action && <Action />}
          </div>
        </DidomiFeatureHeader>
      </div>
      <DidomiNavTabs className="flex-1 flex flex-col">
        <DidomiNavTabHeading data-skeleton className={tx({ '!border-0': loading })}>
          <DidomiNavTabHeaders>
            <DidomiNavTabHeader
              disabled={disableUserRequestsTab}
              iconRight={disableUserRequestsTab ? 'lock' : ''}
              disabledTooltip="Sorry you don’t have access to this content, please contact your administrator"
            >
              <NavLink data-testid="nav-link-user-requests" activeClassName="didomi-active-tab" to={requestsRoute}>
                User Requests
              </NavLink>
            </DidomiNavTabHeader>

            <DidomiNavTabHeader
              disabled={disableWidgetsTab}
              iconRight={disableWidgetsTab ? 'lock' : ''}
              disabledTooltip="Sorry you don’t have access to this content, please contact your administrator"
              data-testid="nav-tab-header-widgets"
            >
              <NavLink data-testid="nav-link-widgets" activeClassName="didomi-active-tab" to={widgetsRoute}>
                Widgets
              </NavLink>
            </DidomiNavTabHeader>
          </DidomiNavTabHeaders>
        </DidomiNavTabHeading>
        <DidomiNavTabContents data-skeleton className={tx('flex-1 flex flex-col', { 'mt-s': loading })}>
          {!loading && <DidomiNavTabContent className="flex-1">{children}</DidomiNavTabContent>}
        </DidomiNavTabContents>
      </DidomiNavTabs>
    </DidomiSkeleton>
    <ChatBubbleSpacer />
  </section>
);

export { MainLayout };
