import React, { ReactChild } from 'react';
import { DidomiIllustration, DidomiSecondaryHeader, DidomiBackButtonLink } from '@didomi/ui-atoms-react';
import { useHistory } from 'react-router-dom';
import { Illustrations } from '@didomi/ui-foundation';
import { tx } from '@twind/core';

interface SecondaryLayoutProps {
  titleText?: string;
  backPath?: string;
  backText?: string;
  illustration?: Illustrations;
  onGoBackClick?: () => void;
  fullWidth?: boolean;
  headerChildren?: ReactChild;
  actionChildren?: ReactChild;
  children: React.ReactChild;
}

/**
 * Secondary Layout
 * Default layout with header for secondary pages
 */
const SecondaryLayout = ({
  titleText,
  illustration,
  backPath,
  backText,
  fullWidth,
  children,
  headerChildren,
  actionChildren,
  onGoBackClick,
}: SecondaryLayoutProps): JSX.Element => {
  const { push } = useHistory();
  const handleBackButtonClick = (onGoBackClick, backPath?: string) => {
    if (onGoBackClick) {
      onGoBackClick();
    }
    if (backPath) {
      push(backPath);
    }
  };

  return (
    <section className="p-12 pb-6 h-full box-border !flex flex-col">
      <DidomiSecondaryHeader titleText={titleText}>
        <div slot="back-button">
          <DidomiBackButtonLink className="cursor-pointer" onClick={() => handleBackButtonClick(onGoBackClick, backPath)} text={backText} />
        </div>
        {!titleText && <div slot="header-title">{headerChildren}</div>}
        <div slot="actions">{actionChildren}</div>
      </DidomiSecondaryHeader>
      <div className="grid grid-flow-col grid-cols-12 grid-rows-1 auto-cols-min gap-xs mt-m h-full">
        {illustration && <DidomiIllustration name={illustration} className="col-span-2 hidden xl:block" />}
        <div className={tx('col-span-full', { 'md:col-span-8 xl:col-span-6': !fullWidth })}>{children}</div>
      </div>
    </section>
  );
};

export { SecondaryLayout };
