import { isWithinInterval, subDays, subMinutes } from 'date-fns';

// Function to format the given date string in 'en-GB' locale format and return as a string.
export const formatDate = (date: string): string => new Date(date).toLocaleDateString('en-GB');

// Function to check if the given date is less than or one minute old from current time.
export const isLessThanOrOneMinuteOld = (date: Date): Boolean => {
  const now = new Date(); // Current time
  const oneMinuteAgo = subMinutes(now, 1); // Time one minute before current time

  return isWithinInterval(date, { start: oneMinuteAgo, end: now });
};

// Function to check if the given date is less than or one day old from current time.
export const isLessThanOrOneDayOld = (date: Date): Boolean => {
  const now = new Date(); // Current time
  const oneDayAgo = subDays(now, 1); // Time one day before current time

  return isWithinInterval(date, { start: oneDayAgo, end: now });
};
