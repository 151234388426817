import React from 'react';
import { TextHighlight } from '@components';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { Icons } from '@didomi/ui-foundation';
import { tx } from '@twind/core';

interface UserRequestsCardItemInfoProps {
  label: string;
  labelClass?: string;
  text: string;
  icon?: Icons;
  textClass?: string;
  iconClass?: string;
  dataTestid?: string;
  highlight?: string;
}

export const UserRequestsCardItemInfo = ({
  label,
  labelClass,
  text,
  textClass,
  icon,
  iconClass,
  highlight,
  dataTestid = 'user-request-card-item-info',
}: UserRequestsCardItemInfoProps) => {
  return (
    <div className="flex flex-col">
      <div className={tx('font-bold text-[10px] leading-[13px] text-primary-blue-6 mb-1', labelClass)} data-testid={`${dataTestid}-label`}>
        {label}
      </div>
      <div className={tx('text-[13px] leading-[17px] text-primary-blue-6', textClass)} data-testid={`${dataTestid}-text`}>
        {highlight ? <TextHighlight text={text} highlightedText={highlight} /> : text}
      </div>
      {icon && <DidomiIcon name={icon} className={iconClass ? iconClass : ''} data-testid={`${dataTestid}-icon`} />}
    </div>
  );
};
