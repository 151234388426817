import React from 'react';
import { useHistory } from 'react-router-dom';
import { DidomiButton, DidomiTableCard, DidomiTableCardItem } from '@didomi/ui-atoms-react';
import { UpdateRequestStatusDropdown, UserRequestsCardItemInfo, UserRequestsStatusInfo } from '@components';
import { Request } from '../../../../interfaces';
import { RequestStatus } from '@enums';
import { requestsRoute } from '../../../../constants/routes';
import { getUserRightLabel } from '@utils';

interface UserRequestsTableCardsProps {
  request: Request;
  searchText: string;
  className: string;
  headerClass?: string;
  onRequestStatusChange?: (status: RequestStatus) => void;
  isFetching?: boolean;
}

export const UserRequestsTableCard = ({
  request,
  searchText,
  className,
  headerClass,
  onRequestStatusChange,
  isFetching,
}: UserRequestsTableCardsProps) => {
  const history = useHistory();

  const showNote = (request: Request) => !!request.notes[0];
  const lastNoteText =
    (request?.notes?.length || 0) === 0 ? '' : request.notes.sort((a, b) => b.created_at.localeCompare(a.created_at))[0].value;

  return (
    <DidomiTableCard
      key={request.id}
      className={className}
      headerText={getUserRightLabel(request?.user_right)}
      headerClass={headerClass}
      data-testid={`user-request-card-${request.id}`}
    >
      <div className="flex items-center h-[32px]" slot="header-actions">
        <UpdateRequestStatusDropdown request={request} onRequestStatusChange={onRequestStatusChange} statusLoading={isFetching} />
        <DidomiButton size="small" onClick={() => history.push(requestsRoute + '/' + request.id)}>
          Details
        </DidomiButton>
      </div>

      <div slot="content">
        <DidomiTableCardItem>
          <UserRequestsCardItemInfo
            label="TICKET ID"
            text={request.id}
            highlight={searchText}
            dataTestid="table-card-item-ticket-id"
            textClass="font-serif font-bold text-[20px] leading-[30px]"
          />
        </DidomiTableCardItem>

        <DidomiTableCardItem>
          <UserRequestsStatusInfo
            status={request.status}
            createdAt={request?.created_at}
            updatedAt={request?.updated_at}
            expiresAt={request?.expires_at}
          />
        </DidomiTableCardItem>

        <DidomiTableCardItem width={showNote(request) ? 'auto' : '50%'}>
          <UserRequestsCardItemInfo
            label="USER ID"
            text={request.organization_user_id}
            highlight={searchText}
            dataTestid="table-card-item-user-id"
          />
        </DidomiTableCardItem>

        {showNote(request) && (
          <DidomiTableCardItem>
            <UserRequestsCardItemInfo label="TICKET NOTE" text={lastNoteText} dataTestid="table-card-item-note" textClass="italic" />
          </DidomiTableCardItem>
        )}
      </div>
    </DidomiTableCard>
  );
};
