import React from 'react';
import { DidomiTableCardItem } from '@didomi/ui-atoms-react';
import { UserRequestsStatusInfo } from '@components';
import { formatDate } from '@utils';
import { Request } from '@interfaces';
import { UserRequestDetailsCard } from '../UserRequestDetailsCard/UserRequestDetailsCard';
import { UserRequestDetailsItem } from '../UserRequestDetailsItem/UserRequestDetailsItem';

interface UserRequestDetailsRequestInfosProps {
  request: Request;
}

export const UserRequestDetailsRequestInfos = ({ request }: UserRequestDetailsRequestInfosProps) => {
  const dateOfTheRequest = formatDate(request?.created_at);

  return (
    <UserRequestDetailsCard headerText="Request infos">
      <DidomiTableCardItem>
        <UserRequestsStatusInfo
          status={request?.status}
          labelClass="text-[13px] leading-[17px]"
          createdAt={request?.created_at}
          updatedAt={request?.updated_at}
          expiresAt={request?.expires_at}
          hideRequestDate
        />
      </DidomiTableCardItem>
      <UserRequestDetailsItem title="Date of request" content={dateOfTheRequest} />
      <UserRequestDetailsItem title="User ID" content={request?.organization_user_id} />
    </UserRequestDetailsCard>
  );
};
