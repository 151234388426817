import React from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { BrowserRouter, Redirect, Route, Switch, Link } from 'react-router-dom';
import { MainLayout } from '../../layouts/MainLayout/MainLayout';
import { UserRequests } from '../UserRequests/UserRequests';
import { Widgets } from '../Widgets/Widgets';
import { WidgetPreview } from '../WidgetPreview/WidgetPreview';
import { baseDsarRoute, requestsRoute, widgetsRoute, widgetsPreviewRoute, settingsRoute, widgetsEditRoute } from '@constants';
import { UserRequestDetails } from '../UserRequestDetails/UserRequestDetails';
import { UserRequestDetailsLayout } from '../UserRequestDetails/components/UserRequestDetailsLayout/UserRequestDetailsLayout';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';
import { NavigateToDashboard } from '@components';
import { AppUserRequestsHelper } from './components/AppUserRequestsHelper/AppUserRequestsHelper';
import { AppWidgetsHelper } from './components/AppWidgetsHelper/AppWidgetsHelper';
import { useSPAErrorNotifier } from '../../hooks/data/error-notifier/useSPAErrorNotifier.hook';
import { DidomiIconButton } from '@didomi/ui-atoms-react';
import { Settings } from '../Settings/Settings';
import { SecondaryLayout } from '@layouts';
import { EditWidget } from '@pages';
import { TranslationContextProvider } from '@didomi/widgets-utilities';

const SettingsActionButton = () => (
  <>
    <Link to={settingsRoute} data-testid="settings-link">
      <DidomiIconButton variant="rounded-border" icon="settings"></DidomiIconButton>
    </Link>
  </>
);

const App = () => {
  const { organizationId } = useActiveOrganization();
  const { hasAdminAccess, hasDsarRequestsViewAccess, hasDsarWidgetsViewAccess, hasDsarWidgetsEditAccess, isLoading } = useAccessPolicies();
  useSPAErrorNotifier();

  if (isLoading) {
    return null;
  }

  return (
    <BrowserRouter
      basename={`/${organizationId}${baseDsarRoute}`}
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <Switch>
        <Redirect exact path="/widgets/edit/:id" to="/widgets/edit/:id/languages" />

        {hasDsarRequestsViewAccess && (
          <Route exact path={requestsRoute + '/:id'}>
            <UserRequestDetailsLayout>
              <UserRequestDetails />
            </UserRequestDetailsLayout>
          </Route>
        )}
        {hasDsarRequestsViewAccess && (
          <Route path={requestsRoute}>
            <MainLayout
              disableUserRequestsTab={!hasDsarRequestsViewAccess}
              disableWidgetsTab={!hasDsarWidgetsViewAccess}
              TitleAction={hasAdminAccess && SettingsActionButton}
              Action={AppUserRequestsHelper}
            >
              <UserRequests />
            </MainLayout>
          </Route>
        )}
        <Route exact path={widgetsPreviewRoute}>
          <WidgetPreview />
        </Route>
        {hasDsarWidgetsEditAccess && (
          <Route path={widgetsEditRoute}>
            <TranslationContextProvider>
              <EditWidget />
            </TranslationContextProvider>
          </Route>
        )}
        {hasDsarWidgetsViewAccess && (
          <Route exact path={widgetsRoute}>
            <MainLayout
              disableUserRequestsTab={!hasDsarRequestsViewAccess}
              disableWidgetsTab={!hasDsarWidgetsViewAccess}
              TitleAction={hasAdminAccess && SettingsActionButton}
              Action={AppWidgetsHelper}
            >
              <Widgets />
            </MainLayout>
          </Route>
        )}
        <Route path={settingsRoute}>
          <SecondaryLayout titleText={'Ticket Settings'} backText={'Back to the user requests'} backPath={requestsRoute} fullWidth={true}>
            <Settings />
          </SecondaryLayout>
        </Route>
        <Route exact path="/">
          <Redirect to={hasDsarRequestsViewAccess ? requestsRoute : widgetsRoute} />
        </Route>
        <Route path="*">
          <NavigateToDashboard />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export { App };
