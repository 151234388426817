import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useActiveOrganization, useSnackbar } from '@didomi/utility-react';
import {
  useWidgetPreview as useWidgetPreviewShared,
  UseWidgetPreviewFetchFnResult,
  UseWidgetPreviewFetchFn,
} from '@didomi/widgets-utilities';
import { Languages, ShapesInput } from '@didomi/pmp-generator';

import { useFields, useUserRights, useWidgetLayoutComponents, useWidgetLayouts, useWidget, useWidgetThemes } from '@hooks';
import { snakeToCamelCase } from '@utils';
import { Widget, WidgetTheme } from '@interfaces';

const getTheme = (widget: Widget, defaultThemes: WidgetTheme[]): WidgetTheme => {
  if (widget.theme_id === widget.custom_theme_id) {
    return widget.custom_theme;
  }

  return defaultThemes.find(theme => theme.id === widget.theme_id);
};

interface PreviewProps {
  languages?: Languages;
}

export const useWidgetPreview = ({ languages }: PreviewProps = {}) => {
  const { organization } = useActiveOrganization();
  const { displaySnackbar } = useSnackbar();
  const history = useHistory();
  const { fetch: fetchFields } = useFields({ enabled: false });
  const { fetch: fetchUserRights } = useUserRights({ enabled: false });
  const { fetch: fetchWidgetLayoutComponents } = useWidgetLayoutComponents({ enabled: false });
  const { fetch: fetchWidgetLayouts } = useWidgetLayouts({ enabled: false });
  const { fetch: fetchWidget } = useWidget({ enabled: false, $with: ['custom_theme'] });
  const { fetch: fetchWidgetThemes } = useWidgetThemes({ enabled: false });

  const fetchFn: UseWidgetPreviewFetchFn = useCallback(
    async (widgetId: string): Promise<UseWidgetPreviewFetchFnResult> => {
      const [widget, fields, userRights, widgetLayouts, widgetLayoutComponents, defaultThemes] = await Promise.all([
        fetchWidget({ widgetId }),
        fetchFields(),
        fetchUserRights(),
        fetchWidgetLayouts({ widgetId }),
        fetchWidgetLayoutComponents({ widgetId }),
        fetchWidgetThemes('null'),
      ]);

      const previewLanguages = languages || {
        enabled: widget.enabled_languages,
        default: widget.default_language,
      };

      return {
        widget: {
          name: widget.name,
        },
        languages: previewLanguages,
        publicApiKey: organization.public_api_key,
        display: 'wrapped',
        authMethod: null,
        colors: snakeToCamelCase({ ...getTheme(widget, defaultThemes.data) }),
        layoutShape: ShapesInput[widget.layout_shape],
        layout: snakeToCamelCase(widgetLayouts.data[0]),
        layoutComponents: snakeToCamelCase(widgetLayoutComponents.data),
        layoutEntities: null,
        configTree: null,
        fields: fields.data,
        userRights: userRights.data,
      };
    },
    [
      fetchFields,
      fetchUserRights,
      fetchWidget,
      fetchWidgetLayoutComponents,
      fetchWidgetLayouts,
      fetchWidgetThemes,
      organization.public_api_key,
      languages,
    ],
  );

  return useWidgetPreviewShared(fetchFn, {
    onError: () => {
      displaySnackbar('Preview has not loaded', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
    redirectToPreview: widgetId => {
      const previewPage = history.createHref({ pathname: `/widgets/${widgetId}/preview` });
      window.open(previewPage, '_blank');
    },
  });
};
