import { RequestStatus, SortDir, UserRightId, UserRightGroup } from '../enums';

type RequestStatusData = { id: RequestStatus; name: string; icon: string; smallIcon: string };

export const REQUEST_STATUSES: RequestStatusData[] = [
  {
    id: RequestStatus.UNVERIFIED,
    name: 'Unverified',
    icon: 'status-unverified-md',
    smallIcon: 'status-unverified-sm',
  },
  {
    id: RequestStatus.VERIFIED,
    name: 'Verified',
    icon: 'status-verified-md',
    smallIcon: 'status-verified-sm',
  },
  {
    id: RequestStatus.WORK_IN_PROGRESS,
    name: 'Work in progress',
    icon: 'status-wip-md',
    smallIcon: 'status-wip-sm',
  },
  {
    id: RequestStatus.FULFILLED,
    name: 'Fulfilled',
    icon: 'status-fulfilled-md',
    smallIcon: 'status-fulfilled-sm',
  },
  {
    id: RequestStatus.ARCHIVED,
    name: 'Archived',
    icon: 'status-archived-md',
    smallIcon: 'status-archived-sm',
  },
  {
    id: RequestStatus.REFUSED,
    name: 'Refused',
    icon: 'status-refused-md',
    smallIcon: 'status-refused-sm',
  },
];

export const REQUEST_STATUSES_MAP: Record<RequestStatus, RequestStatusData> = REQUEST_STATUSES.reduce((acc, status) => {
  acc[status.id] = status;
  return acc;
}, {} as Record<RequestStatus, RequestStatusData>);

export const USER_REQUEST_ID_LABELS = {
  [`${UserRightId.CPRA_ACCESS_MY_DATA}`]: 'Access data request',
  [`${UserRightId.CPRA_DELETE_MY_DATA}`]: 'Delete data request',
  [`${UserRightId.CPRA_OPT_OUT}`]: 'Opt-out of sales request',
};

// Since some rights will have the same behavior, we list them here
export const USER_REQUEST_RIGHT_GROUP = {
  [`${UserRightId.CPRA_ACCESS_MY_DATA}`]: UserRightGroup.ACCESS_MY_DATA,
  [`${UserRightId.CPRA_DELETE_MY_DATA}`]: UserRightGroup.DELETE_MY_DATA,
  [`${UserRightId.CPRA_OPT_OUT}`]: UserRightGroup.OPT_OUT,
};

export const DEFAULT_ORDER = 'created_at_desc';
export const DEFAULT_STATUS = [];
export const DEFAULT_OR_PARAMS = [];
export const DEFAULT_SEARCH_TEXT = '';

export const REQUEST_FILTERS = {
  userRightId: {
    placeholder: 'Type',
    default: null,
    options: Object.values(UserRightId).map(userRightId => ({ value: userRightId, label: USER_REQUEST_ID_LABELS[userRightId] })),
  },
  status: {
    placeholder: 'Status',
    multi: true,
    defaultValue: [],
    options: REQUEST_STATUSES.map(status => ({ value: status.id, label: status.name, leftIcon: status.icon } as any)),
  },
  order: {
    placeholder: 'Sort',
    defaultValue: 'created_at_desc',
    value: 'created_at_desc',
    options: [
      {
        value: 'created_at_desc',
        defaultValue: 'created_at_desc',
        label: 'Newest',
      },
      {
        value: 'created_at_asc',
        defaultValue: 'created_at_asc',
        label: 'Oldest',
      },
      {
        value: 'expires_at_desc',
        defaultValue: 'expires_at_desc',
        label: 'Least urgent',
      },
      {
        value: 'expires_at_asc',
        defaultValue: 'expires_at_asc',
        label: 'Most urgent',
      },
    ],
  },
};

export const SORT_BY_SELECTED_FILTER = {
  created_at_desc: {
    sort: 'created_at',
    sortDir: SortDir.DESC,
  },
  created_at_asc: {
    sort: 'created_at',
    sortDir: SortDir.ASC,
  },
  expires_at_desc: {
    sort: 'expires_at',
    sortDir: SortDir.DESC,
  },
  expires_at_asc: {
    sort: 'expires_at',
    sortDir: SortDir.ASC,
  },
};
