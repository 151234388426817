import { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { DEFAULT_ORDER, DEFAULT_STATUS, DEFAULT_SEARCH_TEXT, REQUEST_FILTERS } from '../../../constants';
import { useQueryParams } from '@hooks';
import { useGTM } from '@didomi/utility-react';

export const DEBOUNCE_TIME = 500;
export const TRACK_ANALYTICS_DEBOUNCE_TIME = 3000;
const USE_QUERY_PARAMS_OPTIONS = {
  pushHistory: true,
  defaultValues: {
    order: DEFAULT_ORDER,
    searchText: DEFAULT_SEARCH_TEXT,
    status: DEFAULT_STATUS,
  },
};

/**
 * It is declared outside of the hook intentionally.
 *
 * It became necessary, since setQueryParams triggers
 * a full page reload and it's not possible to send
 * analytics in debounced fashion
 */
export const debouncedAnalyticsStatusPush = debounce((pushEventToDataLayer, labels: string[]) => {
  if (labels.length) {
    pushEventToDataLayer && pushEventToDataLayer('dsar-status-filter', { selection_value: labels.join(', ') });
  }
}, TRACK_ANALYTICS_DEBOUNCE_TIME);

/**
 * Hook used to get the filters from the query params and to update the query params when the filters change.
 * Exposes the functions for updating and clearing the filters as well as the filter values that can be used to form the request query.
 */
export const useUserRequestFilters = () => {
  const { pushEventToDataLayer } = useGTM();

  const { getQueryParamOrDefaultValue, setQueryParams, clearQueryParams, areParamsApplied } = useQueryParams(USE_QUERY_PARAMS_OPTIONS);

  const debouncedSearch = debounce(async value => setQueryParams({ searchText: value }), DEBOUNCE_TIME);
  const debouncedAnalyticsSearchPush = debounce((searchValue: string) => {
    if (searchValue.length >= 3) {
      pushEventToDataLayer && pushEventToDataLayer('dsar-search', { selection_value: searchValue });
    }
  }, TRACK_ANALYTICS_DEBOUNCE_TIME);

  const handleSearchTextChange = async (searchTextChangeEvent: CustomEvent) => {
    const searchValue = searchTextChangeEvent.detail;
    debouncedSearch(searchValue);
    debouncedAnalyticsSearchPush(searchValue);
  };

  const handleFilterValueChange = (filterChangeEvent: CustomEvent) => {
    const { key, newValue } = filterChangeEvent.detail;
    const options = REQUEST_FILTERS?.[key]?.options;

    if (newValue) {
      switch (key) {
        case 'order': {
          setQueryParams({ order: newValue });
          const label = options.find(option => option.value === newValue)?.label;
          pushEventToDataLayer && pushEventToDataLayer('dsar-sort', { selection_value: label });
          break;
        }
        case 'status': {
          setQueryParams({ status: newValue });
          const labels = options.filter(option => newValue.includes(option.value))?.map(option => option.label.toLowerCase());
          debouncedAnalyticsStatusPush(pushEventToDataLayer, labels);
          break;
        }
        case 'userRightId': {
          setQueryParams({ userRightId: newValue });
          break;
        }
      }
    }
  };

  const handleClearAllFilters = () => {
    clearQueryParams();
  };

  const filters = useMemo(
    () => ({
      order: getQueryParamOrDefaultValue('order'),
      status: getQueryParamOrDefaultValue('status'),
      searchText: getQueryParamOrDefaultValue('searchText'),
      userRightId: getQueryParamOrDefaultValue('userRightId'),
    }),
    [getQueryParamOrDefaultValue],
  );

  return {
    filters,
    handleSearchTextChange,
    handleFilterValueChange,
    handleClearAllFilters,
    hasFilterApplied: areParamsApplied,
  };
};
