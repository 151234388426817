import React from 'react';
import { connect } from '@didomi/ui-foundation';
import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';
import { App } from './pages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from '@didomi/helpers-react';
import { onReactQueryError } from './utils/on-react-query-error.util';

const queryClient = new QueryClient({
  logger: {
    error: onReactQueryError,
    log: () => {},
    warn: () => {},
  },
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  },
});
const isDevEnvironment = CONFIG.environment === 'development';
const isStagingEnvironment = CONFIG.environment === 'staging';

if (isDevEnvironment || isStagingEnvironment) {
  // makeServer();
}

// Setup Twind for dsar only
connect('.dsar-root');

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UtilityProvider utility={utility}>
        <ErrorBoundary
          sentryConfig={{
            sentryDsn: CONFIG.sentryDsn,
            environment: CONFIG.environment,
            release: CONFIG.commitSha,
          }}
        >
          <App />
        </ErrorBoundary>
      </UtilityProvider>
      <ReactQueryDevtools initialIsOpen={isDevEnvironment} />
    </QueryClientProvider>
  );
};

export { Root };
