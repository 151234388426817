import { useSPARouter } from '@didomi/utility-react';
import { dashboardRoute } from '../../constants/routes';
import React, { useLayoutEffect } from 'react';
import { WidgetTable } from './components/WidgetTable/WidgetTable';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';

/**
 * Widgets Default Page
 */
const Widgets = (): JSX.Element => {
  const { navigateTo } = useSPARouter();

  const { isLoading: isAccessPoliciesLoading, hasAdminAccess, hasDsarWidgetsViewAccess, hasDsarWidgetsEditAccess } = useAccessPolicies();

  useLayoutEffect(() => {
    if (isAccessPoliciesLoading) return;

    if (!hasAdminAccess && !hasDsarWidgetsEditAccess && !hasDsarWidgetsViewAccess) {
      navigateTo(dashboardRoute);
    }
  }, [hasAdminAccess, hasDsarWidgetsEditAccess, hasDsarWidgetsViewAccess, isAccessPoliciesLoading, navigateTo]);

  return (
    <div className="w-full h-full pt-6 flex flex-col">
      <WidgetTable />
    </div>
  );
};

export { Widgets };
