import React from 'react';
import { PublishedWidgetModal, PublishedWidgetModalVariant } from '@didomi/widgets-utilities';
import { useHistory } from 'react-router-dom';
import { getWidgetDomainURL } from '@utils';
import { WidgetFormat } from '@enums';

interface Props {
  isOpen: boolean;
  widgetFormat: WidgetFormat;
  widgetId?: string;
  widgetDomain?: string;
  widgetSubdomainId?: string;
  widgetCustomDomainId?: string;
  onCloseModal: () => void;
}

function EditWidgetPublishedModal({
  isOpen,
  widgetId,
  widgetFormat,
  widgetDomain,
  widgetSubdomainId,
  widgetCustomDomainId,
  onCloseModal,
}: Props) {
  const history = useHistory();

  return (
    <>
      {widgetFormat === WidgetFormat.HOSTED && (
        <PublishedWidgetModal
          variant={PublishedWidgetModalVariant.Hosting}
          isOpen={isOpen}
          domainUrl={getWidgetDomainURL({
            domain: widgetDomain,
            subdomainId: widgetSubdomainId,
            customDomainId: widgetCustomDomainId,
          })}
          onCancel={onCloseModal}
          onProceed={() =>
            window
              .open(
                getWidgetDomainURL({
                  domain: widgetDomain,
                  subdomainId: widgetSubdomainId,
                  customDomainId: widgetCustomDomainId,
                }),
                '_blank',
              )
              .focus()
          }
        />
      )}

      {widgetFormat === WidgetFormat.EMBEDDABLE && (
        <PublishedWidgetModal
          variant={PublishedWidgetModalVariant.Embedding}
          isOpen={isOpen}
          modalTitle="Your widget is ready to be implemented"
          onProceed={() => {
            onCloseModal();
            history.push(`/widgets/edit/${widgetId}/embedding`);
          }}
        />
      )}
    </>
  );
}

export default EditWidgetPublishedModal;
