import { useGTM } from '@didomi/utility-react';
import { useCallback, useEffect } from 'react';

/**
 * This hook works together with ErrorBoundary
 * to push an analytics event in case of an uncaught error
 * on any react component.
 *
 * Should be used only on the root component and called once
 *
 * Note: As React says, event handler errors should be handled separately
 * reference: https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
 */
export const useSPAErrorNotifier = (): void => {
  const { pushEventToDataLayer } = useGTM();

  const onSpaErrorHandler = useCallback(
    error => {
      {
        const {
          detail: { error: errorMessage },
        } = error as CustomEvent<{ error: string }>;

        pushEventToDataLayer && pushEventToDataLayer('error', { selection_value: errorMessage });
      }
    },
    [pushEventToDataLayer],
  );

  useEffect(() => {
    window.addEventListener('on-spa-error', onSpaErrorHandler);

    return function cleanup() {
      window.removeEventListener('on-spa-error', onSpaErrorHandler);
    };
  }, [onSpaErrorHandler]);
};
