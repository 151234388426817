import React from 'react';
import { ChatBubbleSpacer } from '../../../../components/ChatBubbleSpacer/ChatBubbleSpacer';

interface UserRequestDetailsLayoutProps {
  children: React.ReactChild;
}

export const UserRequestDetailsLayout = ({ children }: UserRequestDetailsLayoutProps) => {
  return (
    <section className="p-12 pb-7 h-full box-border !flex flex-col">
      {children}
      <ChatBubbleSpacer />
    </section>
  );
};
