import React, { useState, useEffect, useLayoutEffect } from 'react';
import { tx } from '@twind/core';
import { useMembers } from '../../hooks/data/members/useMembers.hook';
import { useSnackbar } from '@didomi/utility-react';
import { useRequestConfigs } from '../../hooks/data/request-configs/useRequestConfigs.hook';
import {
  DidomiSelectOptions,
  DidomiSelectOption,
  DidomiButton,
  DidomiChipList,
  DidomiChip,
  DidomiSkeleton,
  DidomiErrorState,
  DidomiAutocomplete,
} from '@didomi/ui-atoms-react';
import { useHistory } from 'react-router-dom';
import { useUpdateRequestConfig } from '../../hooks/data/request-configs/useUpdateRequestConfig.hook';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';
import { RequestConfigsResponse } from '@types';
import { requestsRoute, dashboardRoute } from '../../constants/routes';
import { Member } from '@interfaces';

/**
 * User Rights Page
 */
export const Settings = (): JSX.Element => {
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const { refetch: membersRefetch, isError: membersError, isLoading: membersLoading, data: { data: members } = {} } = useMembers();
  const {
    refetch: requestConfigsRefetch,
    isError: requestConfigsError,
    isLoading: requestConfigsLoading,
    data: { data: requestConfigs } = {},
  } = useRequestConfigs();

  const { isLoading: isAccessPoliciesLoading, hasAdminAccess } = useAccessPolicies();

  const isLoading = membersLoading || requestConfigsLoading || isAccessPoliciesLoading;
  const isError = membersError || requestConfigsError;

  const requestConfig: RequestConfigsResponse | undefined = requestConfigs?.[0];
  const { mutate: updateRequestConfig, isLoading: requestConfigUpdateSaving } = useUpdateRequestConfig({
    onSuccess: () => {
      displaySnackbar('Members have been saved!', { icon: 'check' });
    },
    onError: error => {
      displaySnackbar('There was an error saving the members list: ' + error.message, { variant: 'error' });
    },
  });
  const isSaveButtonDisabled = requestConfigUpdateSaving || isLoading;

  useLayoutEffect(() => {
    if (isAccessPoliciesLoading) return;

    if (!hasAdminAccess) history.push(dashboardRoute);
  }, [hasAdminAccess, history, isAccessPoliciesLoading]);

  const memberExists = (memberId: string) => {
    return !!members?.find(member => member.id === memberId);
  };

  const handleSave = async () => {
    updateRequestConfig({ id: requestConfig.id, member_ids: selectedMemberIds });
  };

  const onMembersSelect = async (e: CustomEvent<string[]>) => {
    setSelectedMemberIds(e.detail.filter(memberExists));
  };

  const onSearchChange = async (e: CustomEvent<string>) => {
    const searchQuery = e.detail.toLowerCase();

    if (!searchQuery) {
      setFilteredMembers(members);
    }

    const filteredMembers = members.filter(member => member.user.name.toLowerCase().includes(searchQuery));
    setFilteredMembers(filteredMembers);
  };

  useEffect(() => {
    if (members?.length > 0) {
      setFilteredMembers(members);
    }
  }, [members]);

  useEffect(() => {
    setSelectedMemberIds(requestConfigs?.[0]?.member_ids || []);
  }, [requestConfigs]);

  // We wrap the chiplist, otherwise the page can break when navigating back and forth
  const ChipList = () => (
    <DidomiChipList>
      {selectedMemberIds?.map(memberId => (
        <DidomiChip
          variation="basic"
          data-testid={'member-chip-' + memberId}
          key={memberId}
          label={members?.find(member => member.id === memberId)?.user?.name}
        ></DidomiChip>
      ))}
    </DidomiChipList>
  );

  const refetch = () => {
    if (membersError) {
      membersRefetch();
    }
    if (requestConfigsError) {
      requestConfigsRefetch();
    }
  };

  if (isError) {
    return (
      <DidomiErrorState
        illustration-name="content-loading-error"
        action-name="Reload"
        action-icon="reset"
        className="w-full h-full"
        onActionClick={refetch}
      >
        <div slot="title">It seems to be an error</div>
        <div slot="description">The action could not be completed.</div>
      </DidomiErrorState>
    );
  }

  return (
    <div className="w-[625px]">
      <div className="flex flex-col">
        <span className="text-primary-blue-6 font-semibold leading-5 mb-xxs">Email notifications</span>

        <span className="text-primary-blue-6 text-body-small leading-5 mb-xxs">
          Selected users will receive a notification by email when a request is created and a few days before its expiration.
        </span>

        <div className="w-full">
          {isLoading && (
            <>
              <DidomiSkeleton
                isLoading={true}
                className="h-[65px] w-full mb-[22px] rounded-[4px] mt-4"
                data-testid="settings-select-loading"
              ></DidomiSkeleton>

              <DidomiSkeleton
                isLoading={true}
                className="h-[26px] w-full mb-7 rounded-[8px]"
                data-testid="settings-chips-loading"
              ></DidomiSkeleton>
            </>
          )}

          {!isLoading && (
            <>
              <DidomiAutocomplete
                data-testid="members-autocomplete"
                label="Notified users"
                placeholder={'Select users to notify'}
                name="widget-domain"
                multi={true}
                className="mt-4"
                data-skeleton
                value={selectedMemberIds}
                onValueChange={onMembersSelect}
                onSearchChange={onSearchChange}
              >
                <DidomiSelectOptions>
                  {filteredMembers?.map(member => (
                    <DidomiSelectOption
                      label={member.user.name}
                      value={member.id}
                      key={member.id}
                      selected={selectedMemberIds.includes(member.id)}
                    />
                  ))}
                </DidomiSelectOptions>
              </DidomiAutocomplete>

              <div className={tx({ 'mb-7': selectedMemberIds?.length > 0 })}>
                <ChipList />
              </div>
            </>
          )}

          <div>
            <DidomiButton
              variant="secondary"
              data-testid="settings-cancel-button"
              className={tx('mr-xs')}
              data-skeleton
              onClick={() => history.push(requestsRoute)}
            >
              Cancel
            </DidomiButton>

            <DidomiButton
              data-testid="settings-save-button"
              disabled={isSaveButtonDisabled}
              iconRight="save"
              className="mr-xs"
              onClick={handleSave}
              data-skeleton
            >
              Save
            </DidomiButton>
          </div>
        </div>
      </div>
    </div>
  );
};
