/* eslint-disable no-undef */
export const apiRoutes = {
  requests: `${CONFIG.apiUrl}/dsar/requests`,
  widgets: `${CONFIG.apiUrl}/widgets`,
  widgetLayouts: `${CONFIG.apiUrl}/widgets/layouts`,
  widgetLayoutComponents: `${CONFIG.apiUrl}/widgets/layout-components`,
  widgetThemes: `${CONFIG.apiUrl}/widgets/themes`,
  members: `${CONFIG.apiUrl}/members`,
  interactionEvents: `${CONFIG.apiUrl}/dsar/events`,
  requestConfigs: `${CONFIG.apiUrl}/dsar/configs`,
  requestNotes: `${CONFIG.apiUrl}/dsar/requests/notes`,
  widgetDeployments: `${CONFIG.apiUrl}/widgets/deployments`,
  userRights: `${CONFIG.apiUrl}/dsar/user-rights`,
  fields: `${CONFIG.apiUrl}/dsar/fields`,
  domains: `${CONFIG.apiUrl}/domains`,
};
