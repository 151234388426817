import React from 'react';
import { DidomiHeaderInput, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface Props {
  widgetName: string;
  isLoading: boolean;
  onWidgetNameChange: (widgetName: string) => void;
}

export const EditWidgetHeaderTitle = ({ widgetName, isLoading, onWidgetNameChange }: Props) => {
  return (
    <DidomiSkeleton isLoading={isLoading}>
      <DidomiHeaderInput
        value={widgetName}
        onValueChange={ev => {
          onWidgetNameChange(ev.detail);
        }}
        icon="edit"
        tooltipContent="Edit title"
        className={tx({ 'min-w-[250px] h-10': isLoading })}
        data-skeleton
      />
    </DidomiSkeleton>
  );
};
