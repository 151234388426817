import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { apiRoutes } from '../../../constants/apiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { PaginatedResponse } from '../../../types/responses/pagination';
import { Widget } from '../../../interfaces/widget.interface';
import { WidgetFormat, WidgetLayoutShape, WidgetTemplate } from '@enums';

export const useCreateWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async () =>
    axiosWithInterceptors.post(
      apiRoutes.widgets,
      {
        name: 'My DSAR Form',
        format: WidgetFormat.EMBEDDABLE,
        layout_shape: WidgetLayoutShape.SMOOTHED,
        template_id: WidgetTemplate.DSAR_FORM,
      },
      { params: { organization_id: organizationId } },
    );

  return useMutation<AxiosResponse<Widget>, AxiosError>(post, {
    ...options,
    onSuccess: (createdWidgetRes: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widget'
      const dataKey = ['widget'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Widget> | Widget) => {
        // Data is undefined when enabled option is set to false in the useQuery.
        if (!data) return;

        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            total: data.total + 1,
            data: [createdWidgetRes.data, ...data.data],
          };
        } else if ('id' in data && data.id === createdWidgetRes.data.id) {
          return { ...data, ...createdWidgetRes.data };
        }
      });

      if (options?.onSuccess) options.onSuccess(createdWidgetRes, variables, context);
    },
  });
};
