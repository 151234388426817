import React from 'react';
import { UserRequestDetailsCard } from '../UserRequestDetailsCard/UserRequestDetailsCard';
import { UserRequestDetailsItem } from '../UserRequestDetailsItem/UserRequestDetailsItem';
import { Request } from '../../../../interfaces';

interface UserRequestDetailsAdditionalInfosProps {
  request: Request;
}

export const UserRequestDetailsAdditionalInfos = ({ request }: UserRequestDetailsAdditionalInfosProps) => {
  const parsedFields = (request?.request_fields || []).map(requestField => ({
    key: requestField.id,
    title: requestField.field.label.en,
    content: requestField.value,
  }));
  const fields = parsedFields.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <UserRequestDetailsCard headerText="Additional infos">
      {fields.map(field => (
        <UserRequestDetailsItem key={field.key} title={field.title} content={field.content} />
      ))}
    </UserRequestDetailsCard>
  );
};
