import { useActiveOrganization } from '@didomi/utility-react';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { WidgetDeployment, Widget, ErrorResponse } from '@interfaces';
import { PaginatedResponse } from '@types';
import { WidgetPublicationStatus } from '@enums';

export type CreateWidgetDeployment = {
  widget_id: string;
};

export const useCreateWidgetDeployment = (
  options?: UseMutationOptions<AxiosResponse<WidgetDeployment>, AxiosError<ErrorResponse, any>, CreateWidgetDeployment>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const createWidgetDeployment = async (payload: CreateWidgetDeployment) => {
    const response = await axiosWithInterceptors.post<WidgetDeployment>(apiRoutes.widgetDeployments, payload, {
      params: { organization_id: organizationId },
    });

    return response;
  };

  return useMutation<AxiosResponse<WidgetDeployment>, AxiosError<ErrorResponse, any>, CreateWidgetDeployment>(createWidgetDeployment, {
    ...options,
    onSuccess: (widgetDeploymentResponse: AxiosResponse<WidgetDeployment>, variables, context) => {
      // this will update all queries/caches that start with 'widget'
      const dataKey = ['widget'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Widget> | Widget) => {
        // Data is undefined when enabled option is set to false in the useQuery.
        if (!data) return;

        if ('data' in data && Array.isArray(data.data)) {
          const widgetToUpdate = data.data.find(widget => widget.id === widgetDeploymentResponse.data.widget_id);
          if (widgetToUpdate) {
            widgetToUpdate.publication_status = WidgetPublicationStatus.PUBLISHED;
          }
          return {
            ...data,
            total: data.total + 1,
            data: [...data.data],
          };
        } else if ('id' in data && data.id === widgetDeploymentResponse.data.widget_id) {
          return {
            ...data,
            publication_status: WidgetPublicationStatus.PUBLISHED,
          };
        }
      });

      if (options?.onSuccess) options.onSuccess(widgetDeploymentResponse, variables, context);
    },
  });
};
