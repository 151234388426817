import { apiRoutes } from '../../../constants/apiRoutes';
import { useQuery } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Request } from '../../../interfaces';
import { useActiveOrganization } from '@didomi/utility-react';

export const useRequest = (id?: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchRequest = async () => {
    const params = {
      $with: ['notes', 'regulations', 'user_rights'],
      organization_id: organizationId,
    };
    const response = await axiosWithInterceptors.get<Request>(apiRoutes.requests + '/' + id, { params });

    return response.data;
  };

  return useQuery(['request', id], fetchRequest, { enabled: !!id, cacheTime: 0 });
};
