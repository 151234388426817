import { apiRoutes } from '../../../constants/apiRoutes';
import { useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { usePaginationQueryParams } from '../../usePaginationQueryParams.hook';
import { SORT_BY_SELECTED_FILTER, DEFAULT_CACHE_TIME } from '../../../constants';
import { useActiveOrganization } from '@didomi/utility-react';
import { RequestStatus, UserRightId } from '../../../enums';
import { Request } from '../../../interfaces';

interface UseRequestsProps {
  order: string;
  searchText: string;
  status: RequestStatus[];
  userRightId?: UserRightId;
}

const getRequestSearchTextFields = (searchText: string | undefined) => {
  if (!searchText) {
    return [];
  }

  return [
    {
      organization_user_id: { $ilike: searchText },
    },
    {
      id: { $like: searchText },
    },
  ];
};

export const useRequests = ({ order, searchText, status, userRightId }: UseRequestsProps) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const { sort, sortDir } = SORT_BY_SELECTED_FILTER[order];

  const { limit, page, skip, setLimit, setPage } = usePaginationQueryParams();

  const fetchRequests = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Request>>(apiRoutes.requests, {
      params: {
        $limit: limit,
        $skip: skip,
        organization_id: organizationId,
        $with: ['user_rights', 'notes'],
        $sort: { [`${sort}`]: sortDir },
        $or: getRequestSearchTextFields(searchText),
        status: { $in: status },
        user_right_id: userRightId,
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Request>>(
      [
        'request',
        {
          page,
          limit,
          organization_id: organizationId,
          sort,
          sortDir,
          $or: getRequestSearchTextFields(searchText),
          status: { $in: status },
          user_right_id: userRightId,
        },
      ],
      fetchRequests,
      {
        cacheTime: DEFAULT_CACHE_TIME,
        staleTime: 0,
      },
    ),
    paginator: {
      page,
      limit,
      setPage,
      setLimit,
    },
  };
};
