import React, { useState } from 'react';
import { DidomiDrawer, DidomiDrawerContent, DidomiIllustration, DidomiSmallPush } from '@didomi/ui-atoms-react';

export const AppWidgetsHelper = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <DidomiSmallPush
        data-testid="in-app-helper"
        highlightedText="What is a widget?"
        text="Good to know"
        smallIcon="right-on"
        onClick={() => setIsDrawerOpen(true)}
      />
      <DidomiDrawer
        data-testid="in-app-helper-drawer"
        isOpen={isDrawerOpen}
        panelVisualContainer=".dsar-root"
        onClose={() => setIsDrawerOpen(false)}
      >
        <DidomiDrawerContent>
          <h2 className="h2 text-primary-blue-6">What is a widget?</h2>
          <DidomiIllustration name="widgets-in-app-helper" className="my-s" />
          <div className="text-body-normal text-primary-blue-6">
            <p className="mb-s">
              A <b>widget</b> is an end-user-facing form used to allow end-users to submit privacy requests.
            </p>
            <p className="mb-s">
              A <b>Privacy Requests widget</b> is based on the user's rights granted by privacy laws to consumers in a given country.
            </p>
            <p className="mb-s">
              For each widget, you can customize the color, <b>theme, shapes</b> and <b>content to fit your business and design needs.</b>
            </p>
          </div>
        </DidomiDrawerContent>
      </DidomiDrawer>
    </>
  );
};
