import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiButton, DidomiIllustration, DidomiModalActions } from '@didomi/ui-atoms-react';

interface UpdateRequestStatusModalProps {
  isOpen: boolean;
  onProceed: () => void;
  onCancel: () => void;
}

const UPDATE_REQUEST_MODAL_TITLE = 'You are going to update the status of the request and send an email to the user';

export const UpdateRequestStatusModal = ({ isOpen, onCancel, onProceed }: UpdateRequestStatusModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} onCancel={onCancel} permanent="true" data-testid="update-request-status-modal" variant="slim">
      <DidomiIllustration name="warning-modal" className="mb-10"></DidomiIllustration>
      <DidomiModalHeader
        variant="warning"
        modalTitle={UPDATE_REQUEST_MODAL_TITLE}
        modalSubTitle={'Please pay attention'}
      ></DidomiModalHeader>
      <DidomiModalActions className="!pt-5">
        <DidomiButton variant="secondary" onClick={onCancel} data-testid="update-request-status-cancel">
          No, thanks
        </DidomiButton>
        <DidomiButton onClick={() => onProceed()} data-testid="update-request-status-submit">
          <div className="w-full">Yes, continue</div>
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
