import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { WidgetLayout } from '@interfaces';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

interface UseWidgetLayoutsProps {
  widgetId?: string;
  enabled?: boolean;
}

interface WidgetLayoutFetchOptions {
  widgetId?: string;
}

export const useWidgetLayouts = ({ widgetId, enabled }: UseWidgetLayoutsProps = { enabled: true }) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async (options: WidgetLayoutFetchOptions) => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<WidgetLayout>>(apiRoutes.widgetLayouts, {
      params: {
        organization_id: organizationId,
        widget_id: options.widgetId,
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<WidgetLayout>, AxiosError>(
      ['widget-layouts', { organization_id: organizationId, widget_id: widgetId }],
      () => fetch({ widgetId }),
      { enabled },
    ),
    fetch,
  };
};
