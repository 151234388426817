import { WidgetDomain } from '@didomi/widgets-utilities';

type WidgetDomainProps = {
  domain: string;
  subdomainId: string;
  customDomainId: string;
};

/**
 * Returns the selected domain URL
 * @param {object} widget The widget object
 * @return {string} Selected domain URL
 */
export const getWidgetDomainURL = ({ domain, subdomainId, customDomainId }: WidgetDomainProps) => {
  return `https://${String(domain).toLocaleLowerCase() === WidgetDomain.SUB.toLocaleLowerCase() ? subdomainId : customDomainId}`;
};
