import React from 'react';
import { DidomiBackButtonLink, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';
import { useHistory } from 'react-router-dom';
import { requestsRoute } from '../../../../constants/routes';
import { UserRight } from '@interfaces';
import { getUserRightLabel } from '@utils';

interface UserRequestDetailsHeaderProps {
  requestId: string;
  userRight: UserRight;
}

export const UserRequestDetailsHeader = ({ requestId, userRight }: UserRequestDetailsHeaderProps) => {
  const history = useHistory();

  return (
    <DidomiSecondaryHeader titleText={`${getUserRightLabel(userRight)} #${requestId}`}>
      <div slot="back-button">
        <DidomiBackButtonLink text="Back to the user requests" className="cursor-pointer" onClick={() => history.push(requestsRoute)} />
      </div>
    </DidomiSecondaryHeader>
  );
};
