import React, { useState } from 'react';
import { DidomiDrawer, DidomiDrawerContent, DidomiIllustration, DidomiSmallPush } from '@didomi/ui-atoms-react';

export const AppUserRequestsHelper = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <DidomiSmallPush
        data-testid="in-app-helper"
        highlightedText="What is a user request?"
        text="Good to know"
        smallIcon="right-on"
        onClick={() => setIsDrawerOpen(true)}
      />
      <DidomiDrawer data-testid="in-app-helper-drawer" isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DidomiDrawerContent>
          <h2 className="h2 text-primary-blue-6">What is a user request?</h2>
          <DidomiIllustration name="user-rights-in-app-helper" className="my-s" />
          <div className="text-body-normal text-primary-blue-6">
            <h3 className="h3 mt-[40px] mb-s text-primary-blue-6">User request</h3>
            <p className="mb-s">
              Regulations such as GDPR in the EU and CPRA in California require you to accommodate specific user rights such as access,
              deletion, and modification of user data, as well as opt-out of sales.
            </p>
            <p className="mb-s">
              In this context, a User request (or Privacy request) refers to the request submitted by your end-users/data subjects to
              exercise their rights in accordance with the applicable regulation.
            </p>
            <p className="mb-s">
              Any end-user/data subject (that is, anyone whose personal data your company has collected and stored) may submit a user
              request. Most of the time, they can be customers, users, employees, ex-employees, partners, sales prospects, contractors,
              suppliers, and so on.
            </p>
            <p className="mb-s">Please refer to the applicable regulation for precise details on who can request.</p>

            <h3 className="h3 mt-[40px] mb-s text-primary-blue-6">The rights</h3>
            <p className="mb-s">
              Data privacy laws give certain data rights to consumers and it varies from regulation to regulation. Some of the main rights
              that these regulations have in common are:
            </p>
            <ul className="list-disc list-inside pl-xxs">
              <li>
                <b>The right of access</b> (or right to know): The right of a user to find out what personal data a company holds on them
                and to receive a copy of the data held.
              </li>
              <li>
                <b>The right of deletion</b>: The right of a user to erase the personal information that a company has collected from them.
              </li>
              <li>
                <b>The right to opt-out</b>: The right of a data subject to withdraw their consent to the processing of their personal
                information (for example, a request to “do not sell” my personal data).
              </li>
            </ul>
            <p>Please note, these are general sets of rights, for accurate information please refer to the applicable regulation.</p>
          </div>
        </DidomiDrawerContent>
      </DidomiDrawer>
    </>
  );
};
