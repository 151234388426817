import React, { useState, useCallback, useMemo } from 'react';
import { WIDGET_STATIC_USERS_RIGHTS, WIDGET_STATUS_CHIP_TYPE, WIDGET_STATUS_LABELS, WIDGET_USERS_RIGHTS_LABELS } from '@constants';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiChip,
  DidomiEmptyState,
  DidomiErrorState,
  DidomiListShortener,
  DidomiFiltersBar,
  DidomiButton,
  DidomiTooltip,
  DidomiIconButton,
  DidomiMenu,
  DidomiMenuItem,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';
import { WidgetPublicationStatus } from '@enums';
import { useSnackbar } from '@didomi/utility-react';
import {
  useResizeObserver,
  useCreateWidget,
  useDeleteWidget,
  useWidgets,
  useDuplicateWidget,
  useCreateWidgetDeployment,
  useAccessPolicies,
} from '@hooks';
import { LoadingModal, DeleteWidgetModal, PublishWidgetModal } from '@modals';
import { Widget, WidgetDeployment } from '@interfaces';
import { TextHighlight, WidgetPreview } from '@components';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';

export const REFETCH_WIDGETS_DEBOUNCE_TIME = 500;

const WidgetTable = (): JSX.Element => {
  const [widgetTitleColumn, setWidgetTitleColumn] = useState<HTMLDidomiTableTdElement>(null);
  const [widgetColumnWidth, setWidgetColumnWidth] = useState<number>();
  const [currentWidgetIdAction, setCurrentWidgetIdAction] = useState<string>(null);
  const [deleteCandidate, setDeleteCandidate] = useState<Pick<Widget, 'publication_status' | 'id'>>(null);
  const [widgetDeployment, setWidgetDeployment] = useState<WidgetDeployment>(null);
  const [searchText, setSearchText] = useState<string>('');

  const { displaySnackbar } = useSnackbar();
  const { push } = useHistory();

  const { hasDsarWidgetsEditAccess } = useAccessPolicies();

  const { mutate: duplicateWidget } = useDuplicateWidget({
    onSuccess: () => {
      setCurrentWidgetIdAction(null);
      displaySnackbar('Copy of your widget is created', { icon: 'check' });
    },
    onError: () => {
      setCurrentWidgetIdAction(null);
      displaySnackbar('widget can not be duplicated', { icon: 'danger-light', variant: 'error', title: 'Sorry, an error occurred:' });
    },
  });

  const { mutate: deleteWidget, isLoading: deletingWidget } = useDeleteWidget({
    onSuccess: () => {
      const action = deleteCandidate.publication_status === WidgetPublicationStatus.DRAFT ? 'deleted' : 'archived';
      displaySnackbar(`Widget is ${action}!`, { icon: 'check' });
      setDeleteCandidate(null);
      refetchWidgets();
    },
    onError: () => {
      const action = deleteCandidate.publication_status === WidgetPublicationStatus.DRAFT ? 'deleted' : 'archived';
      displaySnackbar(`Sorry, an error occurred: widget can not be ${action}`, { icon: 'danger-light' });
      setDeleteCandidate(null);
    },
  });

  const {
    isLoading: isWidgetsLoading,
    isRefetching: isWidgetsRefetching,
    isError: isWidgetsError,
    refetch: refetchWidgets,
    isInitialLoading: isWidgetInitialLoading,
    paginator: { page, limit, setLimit, setPage },
    data: { data: widgets, total: widgetsCount } = {},
  } = useWidgets({
    searchText,
    onError: () => {
      displaySnackbar('Widgets list cannot be loaded', {
        variant: 'error',
        permanent: true,
        title: 'An error occurred:',
        action: {
          action: /* istanbul ignore next */ () => {
            refetchWidgets();
          },
          name: 'Reload',
        },
        secondaryAction: {
          action: /* istanbul ignore next */ () => {},
          name: 'Close',
          closeInAction: true,
        },
      });
    },
  });

  //Saves for the first load, if there is widgets on the database.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasWidgetsSaved = useMemo(() => widgetsCount > 0, [isWidgetInitialLoading]);

  const { mutate: createWidget, isLoading: isCreationWidgetLoading } = useCreateWidget({
    onSuccess: () => displaySnackbar('Your widget has been created!', { icon: 'check' }),
    onError: () => displaySnackbar('An error occurred: your widget has not been created', { variant: 'error' }),
  });

  const { mutate: createWidgetDeployment } = useCreateWidgetDeployment({
    onSuccess: data => {
      setCurrentWidgetIdAction(null);
      setWidgetDeployment(data.data);
      refetchWidgets();
    },
    onError: () => {
      setCurrentWidgetIdAction(null);
      displaySnackbar('An error occurred: your widget has not been published', { variant: 'error' });
    },
  });

  const handleWidgetCreation = () => createWidget();

  const onDuplicateWidget = (widgetId: string) => {
    setCurrentWidgetIdAction(widgetId);
    duplicateWidget({
      widget_id: widgetId,
    });
  };

  const onPublishWidget = (widgetId: string) => {
    setCurrentWidgetIdAction(widgetId);
    createWidgetDeployment({ widget_id: widgetId });
  };

  const confirmWidgetDeletion = () => {
    if (deleteCandidate) {
      deleteWidget({
        widget_id: deleteCandidate.id,
      });
    }
  };

  const handleWidgetTitleColumnResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const rowElement = entries[0].target;
    const { width } = rowElement.getBoundingClientRect();
    const paddingLeft = 32;
    setWidgetColumnWidth(width - paddingLeft);
  }, []);

  useResizeObserver(widgetTitleColumn, handleWidgetTitleColumnResize);

  const widgetsList = widgets || [];

  const getLeftText = () => {
    if (isWidgetsLoading || isWidgetsRefetching) {
      return 'Loading..';
    }

    if (isWidgetsError) {
      return null;
    }

    return widgetsCount === 1 ? `${widgetsCount} result` : `${widgetsCount} results`;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchWidgetsDebounced = useCallback(
    debounce(() => refetchWidgets(), REFETCH_WIDGETS_DEBOUNCE_TIME),
    [refetchWidgets],
  );

  const handleSearchTextChange = (event: CustomEvent<string>) => {
    setSearchText(event.detail);
    refetchWidgetsDebounced();
  };

  const handleClearAllFilters = () => {
    setSearchText('');
    refetchWidgetsDebounced();
  };

  const isLoading = isWidgetsLoading || (isWidgetsRefetching && widgetsList.length === 0);
  const displayTable = (isLoading || !!widgetsCount) && !isWidgetsError;

  return (
    <div className="flex flex-col flex-1" data-testid={`widgets-loading-${isLoading}`}>
      <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-xs">
        <DidomiFiltersBar
          className="flex-1"
          leftText={getLeftText()}
          showSearch={hasWidgetsSaved}
          collapsibleFilters={false}
          onSearchTextChange={handleSearchTextChange}
          // onFilterValueChange={handleFilterValueChange}
          onClearAllFilters={handleClearAllFilters}
          placeholderTextFilter={'Search by Widget ID or Name'}
          data-testid="widgets-filters"
          searchInputCollapsibleWidth="410"
        ></DidomiFiltersBar>
        {hasDsarWidgetsEditAccess && (
          <div>
            <DidomiButton data-tracking="dsar-create-widget" onClick={handleWidgetCreation} iconRight="new-create">
              Create a widget
            </DidomiButton>
          </div>
        )}
      </div>
      {displayTable && (
        <>
          <DidomiTable data-testid={isLoading ? 'loading-table' : 'widget-table'} className="mb-4 w-auto">
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh>NAME</DidomiTableTh>
                <DidomiTableTh>REQUEST TYPE</DidomiTableTh>
                <DidomiTableTh style={{ maxWidth: 200 }}>PUBLICATION STATUS</DidomiTableTh>
                <DidomiTableTh cellAlign="center" style={{ maxWidth: 350 }}></DidomiTableTh>
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {isLoading
                ? Array.from({ length: limit }).map((_, i) => (
                    <DidomiTableRow key={'loading-widget-' + i}>
                      <DidomiTableTd class="children:(w-full)">
                        <DidomiSkeleton isLoading={isLoading} data-testid={`widget-skeleton-1-${i + 1}`} className="h-s w-full pr-s" />
                      </DidomiTableTd>
                      <DidomiTableTd class="children:(w-full)">
                        <DidomiSkeleton isLoading={isLoading} data-testid={`widget-skeleton-2-${i + 1}`} className="h-s w-full pr-s" />
                      </DidomiTableTd>
                      <DidomiTableTd class="children:(w-full)">
                        <DidomiSkeleton isLoading={isLoading} data-testid={`widget-skeleton-3-${i + 1}`} className="h-s w-full pr-s" />
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))
                : widgetsList?.map(({ id, name, publication_status }, index) => {
                    const statusChipVariation = publication_status === WidgetPublicationStatus.DRAFT ? 'basic-outline' : 'basic';
                    const isExecutingAction = id === currentWidgetIdAction;
                    const disableActions = currentWidgetIdAction !== null ? true : null;

                    return (
                      <DidomiTableRow key={id} selectionValue={id} data-testid={id}>
                        <DidomiTableTd ref={index === 0 ? setWidgetTitleColumn : undefined}>
                          <div className="flex flex-col">
                            <div className="!outline-none">
                              <WidgetTitle
                                id={id}
                                title={name}
                                width={widgetColumnWidth}
                                isLink={false /* TODO use hasDsarWidgetsEditAccess when we have the edit widget page */}
                                searchText={searchText}
                              />
                            </div>
                            <div className="flex items-center gap-xxxs text-primary-blue-4 text-[11px] leading-[14px]">
                              <TextHighlight text={`ID - ${id}`} highlightedText={searchText} />
                            </div>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div className="truncate py-1">
                            {
                              <DidomiListShortener
                                className="cursor-pointer"
                                items={WIDGET_STATIC_USERS_RIGHTS.map(rights => WIDGET_USERS_RIGHTS_LABELS[rights])}
                                itemsToShow={1}
                              ></DidomiListShortener>
                            }
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd style={{ maxWidth: 200 }}>
                          <DidomiChip
                            data-testid={`widget-status-${publication_status}`}
                            variation={statusChipVariation}
                            label={WIDGET_STATUS_LABELS[publication_status]}
                            basicType={WIDGET_STATUS_CHIP_TYPE[publication_status]}
                          />
                        </DidomiTableTd>

                        <DidomiTableTd cellAlign="right" style={{ maxWidth: 350 }}>
                          <div className="flex p-1">
                            <WidgetPreview widgetId={id}>
                              {({ preview, isLoading }) => {
                                // When disabled is false, the tooltip was not showing.
                                // TODO - Remove this code after fix this bug on ui-library
                                const disabled = disableActions || isLoading ? true : null;
                                return (
                                  <DidomiTooltip placement="top" distance="xxxs" content="Preview widget">
                                    <DidomiIconButton
                                      icon={isLoading ? 'reset' : 'preview'}
                                      data-testid={`widget-preview-${id}`}
                                      variant="rounded"
                                      aria-label="Preview widget"
                                      onClick={() => preview()}
                                      disabled={disabled}
                                      className={tx('mr-xs cursor-pointer', {
                                        'animate-spin': isLoading,
                                      })}
                                    />
                                  </DidomiTooltip>
                                );
                              }}
                            </WidgetPreview>

                            {hasDsarWidgetsEditAccess && (
                              <>
                                <DidomiTooltip placement="top" distance="xxxs" content="Edit widget">
                                  <DidomiIconButton
                                    data-testid={`widget-edit-${id}`}
                                    icon="edit"
                                    variant="rounded"
                                    aria-label="Edit widget"
                                    className="mr-xs"
                                    onClick={() => push(`/widgets/edit/${id}`)}
                                    disabled={disableActions}
                                  />
                                </DidomiTooltip>

                                <DidomiTooltip placement="top" distance="xxxs" content="Options">
                                  <DidomiIconButton
                                    id={`widget-options-${id}`}
                                    data-testid={`submenu-${id}-${disableActions}`}
                                    icon={isExecutingAction ? 'reset' : 'submenu'}
                                    variant="rounded"
                                    aria-label="Options"
                                    disabled={disableActions}
                                    className={tx('mr-xs cursor-pointer', {
                                      'animate-spin': isExecutingAction,
                                    })}
                                  />
                                </DidomiTooltip>

                                <DidomiMenu for={`widget-options-${id}`} placement="left-start">
                                  <div>
                                    <DidomiMenuItem
                                      data-testid={`publish-${id}-${isExecutingAction}`}
                                      data-tracking="dsar-publish-widget"
                                      onItemSelected={() => !isExecutingAction && onPublishWidget(id)}
                                      iconName="save-publish"
                                      disabled={publication_status === WidgetPublicationStatus.PUBLISHED}
                                    >
                                      Publish
                                    </DidomiMenuItem>
                                    <DidomiMenuItem
                                      data-testid={`duplicate-${id}-${isExecutingAction}`}
                                      data-tracking="dsar-duplicate-widget"
                                      onItemSelected={() => !isExecutingAction && onDuplicateWidget(id)}
                                      iconName="copy"
                                    >
                                      Duplicate
                                    </DidomiMenuItem>
                                    <DidomiMenuItem
                                      data-testid={`archive`}
                                      iconName="archive"
                                      disabled={publication_status === WidgetPublicationStatus.DRAFT}
                                      onItemSelected={() => setDeleteCandidate({ id, publication_status })}
                                    >
                                      Archive
                                    </DidomiMenuItem>
                                    <DidomiMenuItem
                                      data-testid={`delete`}
                                      iconName="delete"
                                      disabled={publication_status !== WidgetPublicationStatus.DRAFT}
                                      onItemSelected={() => setDeleteCandidate({ id, publication_status })}
                                    >
                                      Delete
                                    </DidomiMenuItem>
                                  </div>
                                </DidomiMenu>
                              </>
                            )}
                          </div>
                        </DidomiTableTd>
                      </DidomiTableRow>
                    );
                  })}
            </DidomiTableBody>
          </DidomiTable>

          <DidomiPaginator
            data-testid="widget-paginator"
            className="self-end mb-s"
            page={page}
            itemCount={widgetsCount}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setPage}
          />
        </>
      )}

      {!isLoading && isWidgetsError && (
        <div className="h-full">
          <DidomiErrorState
            className="h-full"
            illustrationName="content-loading-error"
            actionName="Reload"
            actionIcon="reset"
            onActionClick={() => refetchWidgets()}
          >
            <div slot="title">It seems to be an error</div>
            <div slot="description">Widget list cannot be loaded</div>
          </DidomiErrorState>
        </div>
      )}
      {!widgetsCount && !isLoading && !isWidgetsError && (
        <div className="h-full">
          <DidomiEmptyState
            data-testid="widget-empty-state"
            illustrationName="traces-no-match-found"
            actionName={hasDsarWidgetsEditAccess && !hasWidgetsSaved ? 'Create a Widget' : ''}
            actionIconRight={hasDsarWidgetsEditAccess && !hasWidgetsSaved ? 'new-create' : ''}
            onActionClick={hasDsarWidgetsEditAccess && !hasWidgetsSaved && handleWidgetCreation}
          >
            <div slot="title">{hasWidgetsSaved ? 'No matches' : "It's empty here!"}</div>
            {hasWidgetsSaved ? 'Sorry, no matches were found' : "You don't have any widget created yet"}
          </DidomiEmptyState>
        </div>
      )}
      <DeleteWidgetModal
        isOpen={!!deleteCandidate && !deletingWidget}
        onCancel={() => setDeleteCandidate(null)}
        onProceed={confirmWidgetDeletion}
        publicationStatus={deleteCandidate?.publication_status}
      />
      <LoadingModal
        isOpen={deletingWidget}
        title={deleteCandidate?.publication_status === WidgetPublicationStatus.DRAFT ? 'Deleting widget...' : 'Archiving widget...'}
      />
      <LoadingModal title={'We are creating your widget…'} isOpen={isCreationWidgetLoading}></LoadingModal>
      {widgetDeployment !== null && (
        <PublishWidgetModal isOpen widgetId={widgetDeployment?.widget_id} onClose={() => setWidgetDeployment(null)} />
      )}
    </div>
  );
};

export { WidgetTable };
