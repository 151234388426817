import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { Field } from '@interfaces';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

interface UseFieldsProps {
  enabled?: boolean;
}

export const useFields = ({ enabled }: UseFieldsProps = { enabled: true }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Field>>(apiRoutes.fields, {
      params: {
        organization_id: organizationId,
      },
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Field>, AxiosError>(['fields'], fetch, { enabled }),
    fetch,
  };
};
