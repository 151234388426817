import { apiRoutes } from '../../../constants/apiRoutes';
import { useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { usePaginationQueryParams } from '../../usePaginationQueryParams.hook';
import { DEFAULT_CACHE_TIME } from '../../../constants';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '../../../interfaces';

interface UseWidgetsProps {
  onError?: (error: unknown) => void;
  enabled?: boolean;
  searchText?: string;
}

const getWidgetSearchTextFields = (searchText: string): any[] => {
  if (!searchText) {
    return [];
  }

  return [
    {
      id: { $ilike: searchText },
      name: { $ilike: searchText },
    },
  ];
};

export const useWidgets = ({ onError, enabled = true, searchText }: UseWidgetsProps) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const { limit, page, skip, setLimit, setPage } = usePaginationQueryParams();

  const fetchWidgets = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Widget>>(apiRoutes.widgets, {
      params: {
        $limit: limit,
        $skip: skip,
        organization_id: organizationId,
        usage: 'dsar',
        $sort: {},
        $or: getWidgetSearchTextFields(searchText),
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Widget>>(
      [
        'widget',
        {
          page,
          limit,
          organization_id: organizationId,
          usage: 'dsar',
          $or: [],
        },
      ],
      fetchWidgets,
      {
        enabled,
        cacheTime: DEFAULT_CACHE_TIME,
        staleTime: 0,
        onError,
      },
    ),
    paginator: {
      page,
      limit,
      setPage,
      setLimit,
    },
  };
};
