import React from 'react';
import { useParams } from 'react-router-dom';
import { WidgetPreviewPage } from '@didomi/widgets-utilities';

const WidgetPreview = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  return <WidgetPreviewPage previewURL={`${CONFIG.previewURL}/assets/preview.html`} widgetId={id} />;
};

export { WidgetPreview };
