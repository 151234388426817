import {
  Configuration,
  ComponentsListInput,
  LayoutEntityInput,
  Languages,
  ShapesInput,
  LayoutInput,
  ColorsInput,
} from '@didomi/pmp-generator';
import { WidgetLayoutShape } from '@enums';
import { Field, UserRight, WidgetLayoutComponent } from '@interfaces';
import { WidgetPreviewMode } from '@didomi/widgets-utilities';

type buildWidgetPreviewPayloadProps = {
  layout?: LayoutInput;
  components: WidgetLayoutComponent[];
  organization: {
    public_api_key?: string;
  };
  mode: WidgetPreviewMode;
  theme: ColorsInput;
  layoutShape: WidgetLayoutShape;
  display: string;
  headless: boolean;
  updatedComponents?: ComponentsListInput;
  layoutEntities: LayoutEntityInput[];
  languages: Languages;
  fields: Field[];
  userRights: UserRight[];
};

export const buildWidgetPreviewPayload = ({
  display,
  theme,
  layoutShape,
  organization,
  mode,
  headless,
  layout,
  components,
  updatedComponents,
  layoutEntities,
  languages,
  fields,
  userRights,
}: buildWidgetPreviewPayloadProps) => {
  const list = updatedComponents ? Object.values(updatedComponents) : components;

  return Configuration.generateConfiguration({
    apiKey: organization.public_api_key,
    widgets: [
      {
        // For the preview, we don't really need to have the proper widget ID.
        // Since it is easier to keep a static one in the iframe, we use it here as well
        id: 'preview-container',
        mode,
        display,
        headless,
        options: {
          auth: {
            method: null,
            componentOptions: {},
            hideIfNotAuthenticated: false,
          },
        },
        theme: {
          colors: theme,
          layoutShape: ShapesInput[layoutShape],
        },
        layout,
        layoutComponents: list,
        configTree: null,
        layoutEntities,
        languages: {
          enabled: [languages.default], // Forcing the default language be displayed.
          default: languages.default,
        },
        fields,
        userRights,
      },
    ],
  });
};
