import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse, ParsedQs } from 'qs';
type QueryParam = Record<string, any | any[]>;

export interface UseUrlQueryParamOptions {
  pushHistory?: boolean;
  defaultValues?: QueryParam;
}

const createQueryParams = (queryParams: QueryParam, defaultValues: QueryParam, currentQueryParams: ParsedQs) => {
  const newParams = { ...currentQueryParams };

  Object.entries(queryParams).forEach(([key, value]) => {
    const defaultValue = defaultValues ? defaultValues[key] : undefined;
    if (value === defaultValue) {
      delete newParams[key];
    } else {
      newParams[key] = value as unknown as ParsedQs;
    }
  });
  return stringify(newParams, { encode: false, arrayFormat: 'brackets' });
};
/**
 * Hook to manage query params in the URL. Expposes functions to get, set and clear query params.
 */
export const useQueryParams = (options?: UseUrlQueryParamOptions) => {
  const { push, replace } = useHistory();
  const location = useLocation();
  const currentQueryParams = useMemo(() => parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const historyMethod = options?.pushHistory ? push : replace;

  const getQueryParam = useCallback((key: string) => currentQueryParams[key], [currentQueryParams]);
  const areParamsApplied = useMemo(() => Object.keys(currentQueryParams).length > 0, [currentQueryParams]);

  const getQueryParamOrDefaultValue = useCallback(
    (key: string) => {
      return currentQueryParams[key] || options?.defaultValues[key];
    },
    [currentQueryParams, options],
  );

  const setQueryParams = useCallback(
    (queryParams: Record<string, any>) => {
      const newParams = createQueryParams(queryParams, options?.defaultValues, currentQueryParams);
      historyMethod(`${location.pathname}?${newParams}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentQueryParams, historyMethod, location.pathname],
  );

  const clearQueryParams = () => {
    historyMethod(location.pathname);
  };

  return {
    getQueryParam,
    getQueryParamOrDefaultValue,
    setQueryParams,
    clearQueryParams,
    areParamsApplied,
  };
};
