import React from 'react';
import { InteractionEvent } from '../../../../interfaces/interaction-event.interface';
import { DidomiTimelineItem, DidomiIcon } from '@didomi/ui-atoms-react';
import { InteractionEventType } from '../../../../enums/interaction-event-type';
import { RequestStatus } from '@enums';
import { REQUEST_STATUSES_MAP } from '@constants';
import { InteractionEventAction } from '../../../../enums/interaction-event-action';
import { formatDate, isLessThanOrOneDayOld, isLessThanOrOneMinuteOld } from '@utils';
import type { Icons } from '@didomi/ui-foundation';

interface UserRequestDetailsTimelineItemProps {
  title?: string;
  content?: string;
  event: InteractionEvent;
  withBoldLabels: boolean;
  animate: boolean;
}

const REQUEST_STATUS_MESSAGE_MAP: Record<RequestStatus, JSX.Element> = {
  [RequestStatus.UNVERIFIED]: <>Request has been rejected.</>,
  [RequestStatus.VERIFIED]: (
    <>
      Request is now <b>verified</b>.
    </>
  ),
  [RequestStatus.FULFILLED]: (
    <>
      Request is now <b>fulfilled</b>.
    </>
  ),
  [RequestStatus.WORK_IN_PROGRESS]: (
    <>
      Request is now <b>work in progress</b>.
    </>
  ),
  [RequestStatus.ARCHIVED]: (
    <>
      Request has been <b>archived</b>.
    </>
  ),
  [RequestStatus.REFUSED]: <>Request has been rejected.</>,
};

const EMAIL_STATUS_MESSAGE_MAP: { [RequestStatus.FULFILLED]: JSX.Element; [RequestStatus.REFUSED]: JSX.Element } = {
  [RequestStatus.FULFILLED]: <>Notification of completion has been sent.</>,
  [RequestStatus.REFUSED]: <>Notification of refusal has been sent.</>,
};

const getTimelineItemIconName = (eventType: InteractionEventType, action: InteractionEventAction, status: RequestStatus) => {
  if (eventType === InteractionEventType.REQUEST) {
    return action === InteractionEventAction.CREATE
      ? REQUEST_STATUSES_MAP[RequestStatus.UNVERIFIED].smallIcon
      : REQUEST_STATUSES_MAP[status]?.smallIcon;
  }

  if (eventType === InteractionEventType.EMAIL) {
    return 'email-sent-sm';
  }

  if (eventType === InteractionEventType.NOTE) {
    return 'ticket-note-saved-sm';
  }
};

export const UserRequestDetailsTimelineItem = ({ event, withBoldLabels, animate }: UserRequestDetailsTimelineItemProps) => {
  const LabelWrapper = ({ children }) => (withBoldLabels ? <b>{children}</b> : <>{children}</>);

  const DateLabel = ({ dateString }: { dateString: string }) => {
    const date = new Date(dateString);

    if (isLessThanOrOneMinuteOld(date)) {
      return <LabelWrapper>Just now</LabelWrapper>;
    }

    if (isLessThanOrOneDayOld(date)) {
      return <LabelWrapper>Less than a day</LabelWrapper>;
    }

    return <LabelWrapper>{formatDate(dateString)}</LabelWrapper>;
  };

  const EventMessage = () => {
    switch (event.type) {
      case InteractionEventType.NOTE:
        return <LabelWrapper>Ticket note has been saved!</LabelWrapper>;
      case InteractionEventType.EMAIL:
        // TODO make sure this works correctly when we implemented the emails notifications
        return <LabelWrapper>{EMAIL_STATUS_MESSAGE_MAP[event.metadata?.request_status]}</LabelWrapper>;
      case InteractionEventType.REQUEST:
        if (event.action === InteractionEventAction.CREATE) {
          return (
            <LabelWrapper>
              The request (<b>{event.request_id}</b>) was created with status {event.metadata?.request_status?.replace(/_/g, ' ')}.
            </LabelWrapper>
          );
        }

        if (event.action === InteractionEventAction.UPDATE) {
          return <LabelWrapper>{REQUEST_STATUS_MESSAGE_MAP[event.metadata?.request_status]}</LabelWrapper>;
        }
    }

    return <></>;
  };

  return (
    <DidomiTimelineItem key={event.id} animateWhenAdded={animate}>
      <span slot="label" data-testid={'event-date-label-' + event.id}>
        <DateLabel dateString={event.created_at} />
      </span>
      <div slot="description">
        <DidomiIcon
          className="mr-3 w-2 h-2"
          name={getTimelineItemIconName(event.type, event.action, event.metadata?.request_status) as Icons}
        ></DidomiIcon>
        <span data-testid={'event-message-' + event.id}>
          <EventMessage />
        </span>
      </div>
    </DidomiTimelineItem>
  );
};
