import React from 'react';

interface UserRequestDetailsItemProps {
  title?: string;
  content?: string;
}

export const UserRequestDetailsItem = ({ title, content }: UserRequestDetailsItemProps) => {
  const contentClassName = 'text-[13px] leading-[17px] text-primary-blue-6 mb-1';
  const labelClassName = `font-bold ${contentClassName}`;

  return (
    <div>
      <div className={labelClassName}> {title} </div>
      <div className={contentClassName}> {content} </div>
    </div>
  );
};
