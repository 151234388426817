import { AccessTypes, AvailableModules, modules, adminAccessPolicy } from '@didomi/authorization-metadata';

const USER_REQUESTS_POLICIES_METADATA = modules[AvailableModules.UserRequests];
const DSAR_WIDGETS_POLICIES_METADATA = modules[AvailableModules.DSARWidgets];

export const ACCESS_POLICIES_CONFIG = {
  DSAR_REQUEST_VIEWER: USER_REQUESTS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  DSAR_REQUEST_EDITOR: USER_REQUESTS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
  DSAR_WIDGETS_VIEWER: DSAR_WIDGETS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  DSAR_WIDGETS_EDITOR: DSAR_WIDGETS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
  ADMIN: [adminAccessPolicy.id],
};
