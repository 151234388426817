import React, { useCallback, useState } from 'react';
import { useGTM, useSnackbar } from '@didomi/utility-react';
import { RequestStatus, UserRightGroup } from '@enums';
import { useUpdateRequest } from '@hooks';
import { UpdateRequestStatusModal } from './modals/UpdateRequestStatusModal';
import { UpdateRequestStatusModalAccessFulFilled } from './modals/UpdateRequestStatusModalAccessFulFilled';
import { LoadingModal } from '../../modals/LoadingModal/LoadingModal';
import { Request } from '../../interfaces';
import { DidomiSkeleton, DidomiSelect, DidomiSelectOptions, DidomiSelectOption } from '@didomi/ui-atoms-react';
import { USER_REQUEST_RIGHT_GROUP, REQUEST_STATUSES, createUserRequestsStatusSelectAnimation } from '@constants';
import { SelectSize } from '@didomi/ui-atoms/dist/types/components/didomi-select/didomi-select.types';
import { useAccessPolicies } from '../../hooks/access-policies/useAccessPolicies.hook';

interface UpdateRequestStatusDropdownProps {
  request: Request;
  onRequestStatusChange?: (status: RequestStatus) => void;
  dropdownSize?: SelectSize;
  statusLoading?: boolean;
}

const USER_REQUEST_STATUS_SELECT_ANIMATION = createUserRequestsStatusSelectAnimation();

export const UpdateRequestStatusDropdown = ({
  request,
  onRequestStatusChange,
  dropdownSize = 'xs',
  statusLoading = false,
}: UpdateRequestStatusDropdownProps): JSX.Element => {
  const [newStatus, setNewStatus] = useState<RequestStatus>(request.status);
  const [updateRequestStatusModalOpen, setUpdateRequestStatusModalOpen] = useState<boolean>();
  const [updateRequestStatusModalAccessFulfilledOpen, setUpdateRequestStatusModalAccessFulfilledOpen] = useState<boolean>();
  const { displaySnackbar } = useSnackbar();
  const { pushEventToDataLayer } = useGTM();

  const { isLoading: updateRequestConfigLoading, mutate: updateRequest } = useUpdateRequest({
    onSuccess: () => {
      displaySnackbar('Status of your request has been updated!', { icon: 'check' });
      onRequestStatusChange?.(newStatus);
    },
    onError: () => displaySnackbar('Sorry, an error occurred: status of you request has not been updated', { icon: 'error' }),
  });

  const { hasAdminAccess, hasDsarRequestsEditAccess } = useAccessPolicies();

  const handleOnRequestStatusChange = (e: CustomEvent<RequestStatus | undefined>) => {
    const newStatus = e.detail;

    if (newStatus && request.status !== newStatus) {
      setNewStatus(newStatus);
      if (newStatus === RequestStatus.FULFILLED && USER_REQUEST_RIGHT_GROUP[request.user_right_id] === UserRightGroup.ACCESS_MY_DATA) {
        setUpdateRequestStatusModalAccessFulfilledOpen(true);
      } else {
        setUpdateRequestStatusModalOpen(true);
      }
    }
  };

  const handleOnRequestStatusChangeSubmit = useCallback(
    (additionalParams = {}) => {
      updateRequest({ id: request.id, status: newStatus, ...additionalParams });

      // send stats
      if (!pushEventToDataLayer) {
        return;
      }

      // notify about new status
      pushEventToDataLayer('dsar-change-status', { selection_value: newStatus });

      const url = additionalParams?.extra_message_variables?.download_link || null;
      pushEventToDataLayer('dsar-url-link-request', { selection_value: url !== null });
    },
    [request, newStatus, updateRequest, pushEventToDataLayer],
  );

  return (
    <>
      <DidomiSkeleton
        isLoading={statusLoading}
        className="rounded-[4px] mr-xs"
        data-testid={`user-request-select-menu-skeleton-loading-${statusLoading}`}
      >
        <DidomiSelect
          className={USER_REQUEST_STATUS_SELECT_ANIMATION.toString()}
          disabled={!hasAdminAccess && !hasDsarRequestsEditAccess}
          size={dropdownSize}
          value={request.status}
          hideErrorMessage={true}
          data-testid={`user-request-card-select-${request.id}`}
          onValueChange={handleOnRequestStatusChange}
        >
          <DidomiSelectOptions>
            {REQUEST_STATUSES.map(requestStatus => (
              <DidomiSelectOption
                key={requestStatus.id}
                variant="modern"
                value={requestStatus.id}
                leftIcon={requestStatus.icon}
                label={requestStatus.name}
                data-testid={`user-request-status-${requestStatus.name}`}
              />
            ))}
          </DidomiSelectOptions>
        </DidomiSelect>
      </DidomiSkeleton>
      <LoadingModal title={"We're updating your user request..."} isOpen={updateRequestConfigLoading}></LoadingModal>
      <UpdateRequestStatusModal
        isOpen={updateRequestStatusModalOpen}
        onCancel={() => setUpdateRequestStatusModalOpen(false)}
        onProceed={() => {
          setUpdateRequestStatusModalOpen(false);
          handleOnRequestStatusChangeSubmit();
        }}
      />
      <UpdateRequestStatusModalAccessFulFilled
        isOpen={updateRequestStatusModalAccessFulfilledOpen}
        onCancel={() => setUpdateRequestStatusModalAccessFulfilledOpen(false)}
        onProceed={params => {
          setUpdateRequestStatusModalAccessFulfilledOpen(false);
          handleOnRequestStatusChangeSubmit(params);
        }}
      />
    </>
  );
};
