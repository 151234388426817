import React from 'react';
import { DidomiButton, DidomiChip, DidomiIcon, DidomiIconButton, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { WidgetPublicationStatus } from '@enums';
import { WIDGET_STATUS_CHIP_TYPE, WIDGET_STATUS_LABELS } from '@constants';
import { useMediaQuery } from '@didomi/helpers-react';

interface Props {
  widgetPublicationStatus: WidgetPublicationStatus;
  isLoading: boolean;
  isLoadingPreview: boolean;
  isSaveButtonDisabled: boolean;
  isPublishButtonDisabled: boolean;
  onPreviewWidget: () => void;
  onSave: () => void;
  onPublish: () => void;
}

export const EditWidgetHeaderActions = ({
  widgetPublicationStatus,
  isLoading,
  isLoadingPreview,
  isSaveButtonDisabled,
  isPublishButtonDisabled,
  onPreviewWidget,
  onSave,
  onPublish,
}: Props) => {
  const isBigScreen = useMediaQuery('(min-width: 1366px)');
  const statusChipVariation = widgetPublicationStatus === WidgetPublicationStatus.DRAFT ? 'basic-outline' : 'basic';

  return (
    <div className="flex items-center h-full">
      <DidomiSkeleton isLoading={isLoading} className="rounded-lg">
        <DidomiChip
          data-testid={`widget-status-${widgetPublicationStatus}`}
          variation={statusChipVariation}
          label={WIDGET_STATUS_LABELS[widgetPublicationStatus]}
          basicType={WIDGET_STATUS_CHIP_TYPE[widgetPublicationStatus]}
          data-skeleton
          className={tx({ 'min-w-[50px]': isLoading })}
        />
      </DidomiSkeleton>

      <div className="mx-xs border-l border-solid border-neutral-gray-4 self-stretch"></div>

      <DidomiButton
        variant="secondary"
        data-testid="widget-preview-button"
        className={tx('mr-xs', { 'w-36': isBigScreen })}
        onClick={onPreviewWidget}
        disabled={isLoading}
        data-skeleton
      >
        <span className={tx({ hidden: !isBigScreen || isLoadingPreview })}>Preview</span>
        <DidomiIcon
          name={isLoadingPreview ? 'reset' : 'preview'}
          className={tx('align-middle', {
            'animate-spin': isLoadingPreview,
            'ml-2': isBigScreen,
            hidden: isBigScreen && !isLoadingPreview,
          })}
        />
      </DidomiButton>

      {isBigScreen ? (
        <DidomiButton
          data-cy="widget-save-button"
          data-testid="widget-save-button"
          disabled={isSaveButtonDisabled}
          iconRight="save"
          className="mr-xs"
          onClick={onSave}
          data-skeleton
        >
          Save
        </DidomiButton>
      ) : (
        <DidomiIconButton
          data-cy="widget-save-button"
          data-testid="widget-save-button"
          disabled={isSaveButtonDisabled}
          icon="save"
          className="mr-xs"
          onClick={onSave}
          data-skeleton
        />
      )}

      <DidomiButton
        disabled={isPublishButtonDisabled}
        variant="top"
        data-testid="widget-publish-button"
        iconRight="save-publish"
        data-skeleton
        onClick={onPublish}
      >
        Publish
      </DidomiButton>
    </div>
  );
};
