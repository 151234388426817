import { useEffect } from 'react';

/* istanbul ignore next */
export const useResizeObserver = (
  element: HTMLElement | undefined,
  callback: (entries: ResizeObserverEntry[]) => void,
  deps: Array<any> = [],
) => {
  useEffect(() => {
    if (typeof window.ResizeObserver === 'undefined' || !element) {
      return;
    }
    const observer = new ResizeObserver(callback);
    observer.observe(element);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, callback, ...deps]);
};
