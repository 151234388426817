import { useSPARouter } from '@didomi/utility-react';
import { dashboardRoute } from '../../constants/routes';

const NavigateToDashboard = () => {
  const { navigateTo } = useSPARouter();
  navigateTo(dashboardRoute);
  return null;
};

export { NavigateToDashboard };
