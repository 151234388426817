import React from 'react';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiModalContent,
  DidomiModalActions,
  DidomiCopyText,
  DidomiButton,
  DidomiIcon,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';

interface PublishWidgetModalProps {
  isOpen: boolean;
  widgetId: string;
  onClose: () => void;
}

export const PublishWidgetModal = ({ isOpen, widgetId, onClose }: PublishWidgetModalProps) => {
  const { organization } = useActiveOrganization();

  const scriptText = `<script>
    window.didomiConfig = {
      apiKey: '${organization?.public_api_key}',
      app: { apiKey: '${organization?.public_api_key}' },
      components: { version: 2 },
      widgets: [],
    }
  </script>
  <script type="text/javascript" async="true" charset="utf-8" src="${CONFIG.sdkLoader}"></script>`;
  const htmlText = `<didomi-container id="${widgetId}"></didomi-container>`;

  return (
    <DidomiModal className="relative" isOpen={isOpen} onClose={onClose} closable variant="slim">
      <DidomiIcon name="pmp-success" style={{ color: '#265973', marginBottom: '20px' }} />
      <DidomiModalHeader
        modalTitle="Your widget is ready to be implemented"
        modalSubTitle="Well done!"
        modalDescription="Complete your implementation using the SDK script and HTML code snippet below:"
        modalDescriptionSpace="none"
      ></DidomiModalHeader>
      <DidomiModalContent style={{ width: '100%' }}>
        <span
          style={{
            lineHeight: '1.5rem',
            fontWeight: '600',
            color: 'rgba(214,106,73,1)',
            fontSize: '16px',
            fontFamily: 'IBM Plex Sans',
            marginTop: '15px',
            marginBottom: '6px',
          }}
        >
          SDK script
        </span>
        <span
          style={{
            color: 'rgb(29,65,83)',
            fontSize: '14px',
            lineHeight: '1.25rem',
            fontFamily: 'IBM Plex Sans',
          }}
        >
          This script loads the general SDK configuration.
        </span>
        <span
          style={{
            color: 'rgb(29,65,83)',
            fontSize: '14px',
            lineHeight: '1.25rem',
            fontFamily: 'IBM Plex Sans',
            marginBottom: '10px',
          }}
        >
          It only needs to be added once.
        </span>
        <DidomiCopyText
          truncate
          variation="basic-outline"
          text={scriptText}
          is-icon-persistent="true"
          data-testid="embedding-copy-script-text"
        >
          {scriptText}
        </DidomiCopyText>
        <span
          style={{
            lineHeight: '1.5rem',
            fontWeight: '600',
            color: 'rgba(214,106,73,1)',
            fontSize: '16px',
            fontFamily: 'IBM Plex Sans',
            marginTop: '25px',
            marginBottom: '6px',
          }}
        >
          HTML code snippet
        </span>
        <span
          style={{
            color: 'rgb(29,65,83)',
            fontSize: '14px',
            lineHeight: '1.25rem',
            fontFamily: 'IBM Plex Sans',
            marginBottom: '10px',
          }}
        >
          This snippet code is needed to display your widget.
        </span>
        <DidomiCopyText truncate variation="basic-outline" text={htmlText} is-icon-persistent="true" data-testid="embedding-copy-html-text">
          {htmlText}
        </DidomiCopyText>
      </DidomiModalContent>
      <DidomiModalActions>
        <DidomiButton onClick={onClose}>I got it!</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
