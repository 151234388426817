import React, { useCallback, useState } from 'react';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiButton,
  DidomiIllustration,
  DidomiModalActions,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { ExtraMessageVariables } from '../../../interfaces';

interface UpdateRequestStatusModalAccessFulFilledProps {
  isOpen: boolean;
  onProceed: ({ extra_message_variables }: { extra_message_variables: ExtraMessageVariables }) => void;
  onCancel: () => void;
}

const UPDATE_REQUEST_ACCESS_FULFILLED_MODAL_TITLE =
  'Attach a link in your email to enable users to download their data and fulfill the data access request';

export const UpdateRequestStatusModalAccessFulFilled = ({ isOpen, onCancel, onProceed }: UpdateRequestStatusModalAccessFulFilledProps) => {
  const [downloadLinkValue, setDownloadLinkValue] = useState<string>('');

  const handleValueChange = (ev: CustomEvent<string>) => setDownloadLinkValue(ev.detail);

  const handleSubmit = useCallback(() => {
    onProceed({
      extra_message_variables: {
        download_link: downloadLinkValue,
      },
    });
  }, [downloadLinkValue, onProceed]);

  return (
    <DidomiModal
      isOpen={isOpen}
      onCancel={onCancel}
      permanent="true"
      data-testid="update-request-status-modal-access-fulfilled"
      variant="slim"
    >
      <DidomiIllustration name="warning-modal" className="mb-10"></DidomiIllustration>
      <DidomiModalHeader
        variant="warning"
        modalTitle={UPDATE_REQUEST_ACCESS_FULFILLED_MODAL_TITLE}
        modalSubTitle={'Please pay attention'}
      ></DidomiModalHeader>
      <DidomiTextInput
        hideErrorMessage
        label="Link"
        placeholder="https://www..."
        value={downloadLinkValue}
        onValueChange={handleValueChange}
        className="w-full"
        name="Link"
      ></DidomiTextInput>
      <DidomiModalActions className="!pt-5">
        <DidomiButton variant="secondary" onClick={onCancel} data-testid="update-request-status-modal-access-fulfilled-cancel">
          No, thanks
        </DidomiButton>
        <DidomiButton onClick={handleSubmit} data-testid="update-request-status-modal-access-fulfilled-submit">
          <div className="w-full">Yes, continue</div>
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
