import { apiRoutes } from '../../../constants/apiRoutes';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '../../../interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import { PaginatedResponse } from '@types';

type DeleteWidget = {
  widget_id: string;
};

export const useDeleteWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, DeleteWidget>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteEntity = async (payload: DeleteWidget) => {
    const response = await axiosWithInterceptors.delete<Widget>(`${apiRoutes.widgets}/${payload.widget_id}`, {
      params: { organization_id: organizationId },
    });

    return response;
  };

  return useMutation<AxiosResponse<Widget>, AxiosError, DeleteWidget>(deleteEntity, {
    ...options,
    onSuccess: (deleteWidgetRes: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widget'
      const dataKey = ['widget'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Widget> | Widget) => {
        // Data is undefined when enabled option is set to false in the useQuery.
        if (!data) return;

        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            total: data.total - 1,
            data: data.data.filter(widget => widget.id !== deleteWidgetRes.data.id),
          };
        }
      });

      if (options?.onSuccess) options.onSuccess(deleteWidgetRes, variables, context);
    },
  });
};
