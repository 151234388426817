import React from 'react';
import { DidomiTableCard } from '@didomi/ui-atoms-react';

interface UserRequestDetailsCardProps {
  children: React.ReactNode;
  headerText: string;
}

export const UserRequestDetailsCard = ({ children, headerText }: UserRequestDetailsCardProps) => {
  return (
    <DidomiTableCard headerText={headerText} showSeparator={false} size="sm">
      <div slot="content" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', rowGap: '10px', columnGap: '24px' }}>
        {children}
      </div>
    </DidomiTableCard>
  );
};
