import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { WidgetTheme } from '@interfaces';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

interface UseWidgetThemesProps {
  enabled?: boolean;
  organizationId?: string;
}

export const useWidgetThemes = ({ enabled, organizationId: initialOrganizationId }: UseWidgetThemesProps = { enabled: true }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId: activeOrganizationId } = useActiveOrganization();

  const fetch = async (organizationId: string = initialOrganizationId) => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<WidgetTheme>>(apiRoutes.widgetThemes, {
      params: {
        organization_id: organizationId || activeOrganizationId,
      },
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<WidgetTheme>, AxiosError>(['widget-theme'], () => fetch(), { enabled }),
    fetch,
  };
};
