import React from 'react';
import { formatDate } from '@utils';
import { RequestStatus } from '@enums';
import { UserRequestsCardItemInfo } from '../UserRequestsCardItemInfo/UserRequestsCardItemInfo';
import { differenceInDays } from 'date-fns';

interface UserRequestsStatusInfoProps {
  status: RequestStatus;
  hideRequestDate?: boolean;
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
  labelClass?: string;
}

export const UserRequestsStatusInfo = ({
  status,
  createdAt,
  updatedAt,
  expiresAt,
  hideRequestDate = false,
  labelClass,
}: UserRequestsStatusInfoProps): JSX.Element => {
  const displayProgressBar = [RequestStatus.UNVERIFIED, RequestStatus.VERIFIED, RequestStatus.WORK_IN_PROGRESS].includes(status);

  const expiredDays = differenceInDays(new Date(), new Date(expiresAt));
  const dueInDays = differenceInDays(new Date(expiresAt), new Date());

  const expiredText = expiredDays === 0 ? 'Today' : `${expiredDays} days ago`;
  const dueInText = dueInDays === 0 ? 'Today' : `${dueInDays} Days`;

  const createdAtFormatted = formatDate(createdAt);
  const updatedAtFormatted = formatDate(updatedAt);

  const isRequestExpired = new Date(expiresAt) < new Date();
  const isExpired = displayProgressBar && isRequestExpired;
  const isDueIn = displayProgressBar && !isRequestExpired && dueInDays >= 10;
  const isDueInLessThanTenDays = displayProgressBar && !isRequestExpired && dueInDays < 10;

  const dueInLabel = hideRequestDate ? 'Due in' : 'DUE IN';
  const expiredLabel = hideRequestDate ? 'Expired' : 'EXPIRED';
  const dateOfCompletionLabel = hideRequestDate ? 'Date of completion' : 'DATE OF COMPLETION';
  const dateOfArchiveLabel = hideRequestDate ? 'Date of archive' : 'DATE OF ARCHIVE';
  const dateOfRejectionLabel = hideRequestDate ? 'Date of rejection' : 'DATE OF REJECTION';

  return (
    <>
      {isDueIn && (
        <UserRequestsCardItemInfo
          label={dueInLabel}
          labelClass={labelClass}
          text={dueInText}
          icon="success-progress-bar"
          textClass="font-bold !text-[#47AC3A] mb-xxxs"
          iconClass={!hideRequestDate && 'mb-s'}
        />
      )}
      {isDueInLessThanTenDays && (
        <UserRequestsCardItemInfo
          label={dueInLabel}
          labelClass={labelClass}
          text={dueInText}
          icon="warning-progress-bar"
          textClass="font-bold !text-[#F2AD21] mb-xxxs"
          iconClass={!hideRequestDate && 'mb-s'}
        />
      )}
      {isExpired && (
        <UserRequestsCardItemInfo
          label={expiredLabel}
          labelClass={labelClass}
          text={expiredText}
          icon="error-progress-bar"
          textClass="font-bold text-secondary-red-3 mb-xxxs"
          iconClass={!hideRequestDate && 'mb-s'}
        />
      )}
      {status === RequestStatus.FULFILLED && (
        <UserRequestsCardItemInfo
          label={dateOfCompletionLabel}
          labelClass={labelClass}
          text={updatedAtFormatted}
          textClass={!hideRequestDate && 'mb-s'}
        />
      )}
      {status === RequestStatus.ARCHIVED && (
        <UserRequestsCardItemInfo
          label={dateOfArchiveLabel}
          labelClass={labelClass}
          text={updatedAtFormatted}
          textClass={!hideRequestDate && 'mb-s'}
        />
      )}
      {status === RequestStatus.REFUSED && (
        <UserRequestsCardItemInfo
          label={dateOfRejectionLabel}
          labelClass={labelClass}
          text={updatedAtFormatted}
          textClass={!hideRequestDate && 'mb-s'}
        />
      )}

      {!hideRequestDate && (
        <UserRequestsCardItemInfo label="DATE OF REQUEST" text={createdAtFormatted} dataTestid="user-request-card-item-info-request" />
      )}
    </>
  );
};
