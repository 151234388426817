import { apiRoutes } from '../../../constants/apiRoutes';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '../../../interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import { PaginatedResponse } from '@types';

type DuplicateWidgetPayload = {
  widget_id: string;
};

export const useDuplicateWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, DuplicateWidgetPayload>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const post = async (payload: DuplicateWidgetPayload) => {
    const response = await axiosWithInterceptors.post<Widget>(
      `${apiRoutes.widgets}/${payload.widget_id}/duplicate`,
      {},
      {
        params: { organization_id: organizationId },
      },
    );

    return response;
  };

  return useMutation<AxiosResponse<Widget>, AxiosError, DuplicateWidgetPayload>(post, {
    ...options,
    onSuccess: (createdWidgetRes: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widget'
      const dataKey = ['widget'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Widget> | Widget) => {
        // Data is undefined when enabled option is set to false in the useQuery.
        if (!data) return;

        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            total: data.total + 1,
            data: [createdWidgetRes.data, ...data.data],
          };
        }
      });

      if (options?.onSuccess) options.onSuccess(createdWidgetRes, variables, context);
    },
  });
};
