import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { apiRoutes } from '../../../constants/apiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Request } from '../../../interfaces';
import { PaginatedResponse } from '../../../types/responses/pagination';
import { Note } from '../../../interfaces/note.interface';

export type CreateRequestNotePayload = {
  request_id: string;
  value: string;
};

export const useCreateRequestNote = (options?: UseMutationOptions<AxiosResponse<Note>, AxiosError, CreateRequestNotePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async (payload: CreateRequestNotePayload) =>
    axiosWithInterceptors.post(apiRoutes.requestNotes, payload, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<Note>, AxiosError, CreateRequestNotePayload>(post, {
    ...options,
    onSuccess: (createdNoteRes: AxiosResponse<Note>, variables, context) => {
      // this will update all queries/caches that start with 'requests'
      const dataKey = ['request'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<Request> | Request) => {
        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            data: data.data.map(entity =>
              entity.id === variables.request_id ? { ...entity, notes: [createdNoteRes.data as any, ...entity.notes] } : entity,
            ),
          };
        }
      });

      if (options?.onSuccess) options.onSuccess(createdNoteRes, variables, context);
    },
  });
};
