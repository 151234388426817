import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '../../constants/access-policies';

export const useAccessPolicies = () => {
  const { hasAccess: hasDsarRequestsViewAccess, isLoading: isLoadingViewRequestsAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.DSAR_REQUEST_VIEWER,
  );
  const { hasAccess: hasDsarRequestsEditAccess, isLoading: isLoadingEditRequestsAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.DSAR_REQUEST_EDITOR,
  );
  const { hasAccess: hasDsarWidgetsEditAccess, isLoading: isLoadingEditWidgetsAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.DSAR_WIDGETS_EDITOR,
  );
  const { hasAccess: hasDsarWidgetsViewAccess, isLoading: isLoadingViewWidgetsAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.DSAR_WIDGETS_VIEWER,
  );
  const { hasAccess: hasAdminAccess, isLoading: isLoadingAdminAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.ADMIN);

  return {
    hasDsarRequestsViewAccess,
    hasDsarRequestsEditAccess,
    hasDsarWidgetsEditAccess,
    hasDsarWidgetsViewAccess,
    hasAdminAccess,
    isLoading:
      isLoadingViewRequestsAccess ||
      isLoadingEditRequestsAccess ||
      isLoadingAdminAccess ||
      isLoadingEditWidgetsAccess ||
      isLoadingViewWidgetsAccess,
  };
};
