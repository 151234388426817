import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DidomiTooltip } from '@didomi/ui-atoms-react';
import { useIsOverflowing } from '@hooks';
import { tx } from '@twind/core';
import { widgetsRoute } from '@constants';
import { TextHighlight } from '@components';

interface WidgetTitleProps {
  title: string;
  id: string;
  width?: number;
  isLink?: boolean;
  searchText: string;
}

export const WidgetTitle = ({ title, id, width, isLink, searchText }: WidgetTitleProps) => {
  const [linkRef, setLinkRef] = useState<HTMLAnchorElement>();
  const isOverflowing = useIsOverflowing(linkRef);
  const titleClass = tx(
    // eslint-disable-next-line max-len
    'rounded-md box-border block truncate pt-1 outline-offset-1 border-solid border-transparent pb-1',
    {
      'border-b border-solid border-primary-blue-6 mb-1': isOverflowing,
      underline: isLink,
    },
  );

  return (
    <DidomiTooltip data-testid={`widget-tooltip-${id}`} content={isOverflowing ? title : ''} placement="right">
      {isLink ? (
        <NavLink
          ref={setLinkRef}
          style={width ? { width } : undefined}
          data-testid={`widget-title-${id}-${isOverflowing}`}
          className={titleClass}
          to={`${widgetsRoute}/edit/${id}`}
        >
          <TextHighlight text={title} highlightedText={searchText} />
        </NavLink>
      ) : (
        <div style={width ? { width } : undefined} data-testid={`widget-title-${id}-${isOverflowing}`} className={titleClass}>
          <TextHighlight text={title} highlightedText={searchText} />
        </div>
      )}
    </DidomiTooltip>
  );
};
