import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '../../../constants/apiRoutes';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { InteractionEvent } from '../../../interfaces/interaction-event.interface';

type InteractionEventsQueryParams = { organization_id: string; request_id?: string; $sort: { created_at: number } };

export const useInteractionEvents = (options?: UseQueryOptions<PaginatedResponse<InteractionEvent>>, params?: { requestId?: string }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fullParams: InteractionEventsQueryParams = {
    organization_id: organizationId,
    $sort: {
      created_at: -1,
    },
  };

  if (params?.requestId) {
    fullParams.request_id = params.requestId;
  }

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<InteractionEvent>>(apiRoutes.interactionEvents, {
      params: fullParams,
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<InteractionEvent>, AxiosError>(
    ['interactionEvents', { id: params?.requestId, organization_id: organizationId }],
    fetch,
    {
      ...options,
    },
  );
};
