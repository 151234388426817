import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { UserRight } from '@interfaces';
import { PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

interface UseUserRightsProps {
  enabled?: boolean;
}

export const useUserRights = ({ enabled }: UseUserRightsProps = { enabled: true }) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<UserRight>>(apiRoutes.userRights, {
      params: {
        organization_id: organizationId,
      },
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<UserRight>, AxiosError>(['user-rights', { organization_id: organizationId }], fetch, { enabled }),
    fetch,
  };
};
