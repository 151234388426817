import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { Widget } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

interface UseWidgetProps extends UseQueryOptions {
  enabled?: boolean;
  widgetId?: string;
  $with?: string[];
}

interface WidgetFetchOptions {
  widgetId?: string;
}

export const useWidget = ({ enabled, widgetId, $with, onSuccess }: UseWidgetProps = { enabled: true, $with: [] }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async ({ widgetId }: WidgetFetchOptions) => {
    const response = await axiosWithInterceptors.get<Widget>(`${apiRoutes.widgets}/${widgetId}`, {
      params: {
        organization_id: organizationId,
        $with,
      },
    });
    return response.data;
  };

  return {
    ...useQuery<Widget, AxiosError>(['widget', widgetId], () => fetch({ widgetId }), {
      enabled,
      onSuccess(widget: Widget) {
        onSuccess?.(widget);
      },
    }),
    fetch,
  };
};
