import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { Standalone } from '@didomi/utility';

// Entry point for Single SPA
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
});

const { bootstrap, mount: mountOriginal, unmount } = lifecycles;

async function mount(opts) {
  if (CONFIG.standalone) {
    await Standalone.initApp();
  }
  await setUpDidomiUIAtoms('dsar');
  mountOriginal(opts);
}

export { bootstrap, mount, unmount };
